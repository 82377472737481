import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../Api/Api";
import CryptoJS from 'crypto-js';

const SSO = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const getTokenFromHash = (hash) => {
    const params = hash.split("token=");
    setToken(params[1]);
  };

  const getOrgWithObj = () => {
    API.DashBoard.getOrgWithObjAPI()
      .then((response) => {
        navigate("/dashboard"); // Navigate to dashboard on success
      })
      .catch((err) => {
        localStorage.clear();
        navigate("/");
      });
  };

  useEffect(() => {
    getTokenFromHash(window.location.hash);

    if (token) {
      // The secret key used for encryption
      const secretKey = process.env.REACT_APP_SECRET_KEY ?? "KkQJwvHdaYXUTR-FL*6cjHqHtKG2r";
      try {
        var bytes = CryptoJS.AES.decrypt(token, secretKey);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        if (decryptedData && decryptedData.data) {
          localStorage.setItem("EmailID", decryptedData.data.work_email);
          localStorage.setItem("managementUser", JSON.stringify(decryptedData.data));
          localStorage.setItem("managementToken", decryptedData.token);
          getOrgWithObj();
        } else {
          console.error('Decryption failed or decrypted data is invalid');
          navigate("/");
        }
      } catch (error) {
        console.error('Error during decryption', error.stack);
        navigate("/");
      }
    }
  }, [token]);

  return (
    <>
      <p>Loading....</p>
    </>
  );
};

export default SSO;
