import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import setObjective from "../../assets/images/Icons/Pics/Set Objective.png";
import editIcon from "../../assets/images/Icons/SVG/Edit Icon.svg";
import deleteIcon from "../../assets/images/Icons/SVG/Delete Icon.svg";
import departmentIcon from "../../assets/images/Icons/SVG/Icon materialpersonoutline.svg";
import { useFormik } from "formik";
import API from "../../Api/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddNewDepartment = (props) => {
   const location = useLocation();
   const queryParams = new URLSearchParams(location.search);
   const editId = queryParams.get("editId");

   const [departmentListLocally, setDepartmentListLocally] = useState([]);
   const [isEditing, setEditing] = useState(false);
   const [editingId, setEditingId] = useState(null);
   const [isloading, setIsLoading] = useState(false);

   const navigate = useNavigate();

   const validate = (values) => {
      const errors = {};
      const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
      const spaceReg = /^\S.*\S$/;

      if (!values.dept_name) {
         errors.dept_name = "Please enter department name";
      } else if (!spaceReg.test(values.dept_name)) {
         errors.dept_name = "Cannot start & end with a space";
      } else {
         const selectedDeptName = departmentListLocally.find(
            (item) => item.dept_name === values.dept_name && item._id !== editingId
         );
         if (selectedDeptName) {
            errors.dept_name = `Entered department name already exists`;
         }
      }

      if (!values.email) {
         errors.email = "Please enter email";
      } else if (!regex.test(values.email)) {
         errors.email = "This is not a valid email format";
      } else {
         const selectedEmailId = departmentListLocally.find((item) => item.email === values.email && item._id !== editingId);
         if (selectedEmailId) {
            errors.email = `Entered department email already exists`;
         }
      }

      return errors;
   };

   const formik = useFormik({
      initialValues: {
         dept_name: "",
         email: "",
      },
      onSubmit: () => {
         if (isEditing) {
            updateDepartment();
         } else {
            createDepartment();
         }
      },
      validate,
   });

   useEffect(() => {
      getAllDepartments();
   }, []);

   const getAllDepartments = () => {
      setIsLoading(true);
      API.PlanRenew.departmentProgressList()
         .then((response) => {
            const updateData = response.data.data.filter((item) => item.is_active);
            console.log(updateData, "updateData");
            setDepartmentListLocally(updateData);

            // localStorage.setItem("departmentListLocally", JSON.stringify(response.data.data));
            setIsLoading(false);
         })
         .catch((error) => {
            console.log(error);
            setIsLoading(false);
         });
   };
   const createDepartment = () => {
      setIsLoading(true);
      API.PlanRenew.createDepartmentRenew({
         data: {
            dept_name: formik.values.dept_name,
            email: formik.values.email,
         },
      })
         .then((response) => {
            if (response.data.statusCode === 200) {
               formik.resetForm();
               getAllDepartments();
            }
            setIsLoading(false);
         })
         .catch((error) => {
            console.error("Error creating department:", error);
            setIsLoading(false);
         });
   };

   const handleEdit = (id, deptName, email) => {
      setEditingId(id);
      setEditing(true);
      formik.setValues({
         dept_name: deptName,
         email: email,
      });
   };

   const updateDepartment = () => {
      setIsLoading(true);
      API.PlanRenew.updateDepartmentRenewal({
         data: {
            _id: editingId,
            dept_name: formik.values.dept_name,
            email: formik.values.email,
         },
      })
         .then((response) => {
            if (response.data.statusCode === 200) {
               getAllDepartments();
               formik.resetForm();
               setEditing(false);
               setEditingId(null);
               setIsLoading(false);
               toast.success("Department updated successfully!");
            }
         })
         .catch((error) => {
            console.error("Error updating department:", error);
            setIsLoading(false);
         });
   };

   const handleNextButton = () => {
      // localStorage.setItem("departmentListLocallyPublish", JSON.stringify(departmentListLocally));
      // props.setDepartmentListLocallyPublish(departmentListLocally);
      // props.handleNext();
      navigate(`/plan-renewal`, { replace: true });
   };

   const handleCancel = (e) => {
      e.preventDefault();
      formik.resetForm();
      setEditing(false);
      setEditingId(null);
   };

   return (
      <div className="bgColor">
         <div className="row justify-content-center pt-4">
            <div className="col-12 p-5">
               <div className="row justify-content-center">
                  <div className="col-11">
                     <div className="emailoOuterContainer p-5 bg-white">
                        <div className="d-flex align-items-center">
                           <NavLink to="/plan-renewal">
                              <div className="backArrow me-2">
                                 <i className="fa fa-solid fa-chevron-left textDarkBlack"></i>
                              </div>
                           </NavLink>
                           <h2 className="textDarkBlack fw-normal h4 mb-0">Add new Department</h2>
                        </div>
                        <div className="row justify-content-center mt-4">
                           <div className="col-xxl-11 col-12">
                              <div className="row">
                                 <div className="col-xl-6 pe-xl-5 pb-5 border-right">
                                    <p className="textBlueLight mb-1">Add new department and assign it to the owners</p>
                                    <p className="textGrey">Note: For every user we charge ₹XXX.</p>

                                    <form onSubmit={formik.handleSubmit}>
                                       <div className="mb-4">
                                          <div className="form-group">
                                             <input
                                                type="text"
                                                className="form-control border-0 border-radius-10px"
                                                placeholder="Enter department name"
                                                name="dept_name"
                                                value={formik.values.dept_name}
                                                onChange={formik.handleChange}
                                             />
                                          </div>
                                          {formik.errors.dept_name && formik.touched.dept_name && (
                                             <div className="text-danger">{formik.errors.dept_name}</div>
                                          )}
                                       </div>
                                       <div className="mb-4">
                                          <div className="form-group">
                                             <input
                                                type="email"
                                                className="form-control border-0 border-radius-10px"
                                                placeholder="Enter department owner's email address"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                readOnly={isEditing}
                                             />
                                          </div>
                                          {formik.errors.email && formik.touched.email && (
                                             <div className="text-danger">{formik.errors.email}</div>
                                          )}
                                       </div>
                                       {!isEditing ? (
                                          <button type="submit" className="btn set-objective-button w-100" disabled={isloading}>
                                             {isloading ? "Please Wait..." : "Publish"}
                                          </button>
                                       ) : (
                                          <div className="d-flex justify-content-between">
                                             <button
                                                type="button"
                                                className="btn set-objective-button px-5"
                                                onClick={handleCancel}
                                                disabled={isloading}
                                             >
                                                Cancel
                                             </button>
                                             <button
                                                type="submit"
                                                className="btn sign-up-button-color text-white px-5"
                                                disabled={isloading}
                                             >
                                                {isloading ? "Please Wait..." : "Update"}
                                             </button>
                                          </div>
                                       )}
                                    </form>
                                 </div>
                                 <div className="col-xl-6 ps-xl-5">
                                    <p className="textBlueLight mb-1">
                                       Here are the list of your existing departments and their departmental owners
                                    </p>
                                    {!departmentListLocally.length ? (
                                       <div className="row">
                                          <div className="col-12 text-center py-5">
                                             <img src={setObjective} alt="department name" />
                                          </div>
                                       </div>
                                    ) : (
                                       <>
                                          <div className="d-flex align-items-center justify-content-end mb-2">
                                             <img
                                                src={departmentIcon}
                                                className="me-1"
                                                style={{ width: "15px" }}
                                                alt="department icon"
                                             />{" "}
                                             {departmentListLocally.length}
                                          </div>
                                          <div className="scroll__y">
                                             {departmentListLocally.map((item, index) => (
                                                <div className="row d-flex align-items-center w-100 mb-2" key={index}>
                                                   <div className="col-11">
                                                      <div className="departmentBox p-3">
                                                         <div className="row align-items-center">
                                                            <div className="col-2">
                                                               <img src={departmentIcon} alt="department icon" />
                                                            </div>
                                                            <div className="col-10">
                                                               <p className="mb-1" style={{ overflowWrap: "break-word" }}>
                                                                  {item.dept_name}
                                                               </p>
                                                               <p
                                                                  className="textBlueLight1 mb-0"
                                                                  style={{ overflowWrap: "anywhere" }}
                                                               >
                                                                  {item.email}
                                                               </p>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div className="col-1">
                                                      <div className="d-flex">
                                                         <img
                                                            width={36}
                                                            className="me-2"
                                                            alt="edit icon"
                                                            src={editIcon}
                                                            onClick={() => handleEdit(item._id, item.dept_name, item.email)}
                                                            style={{ cursor: "pointer" }}
                                                         />
                                                      </div>
                                                   </div>
                                                </div>
                                             ))}
                                          </div>
                                       </>
                                    )}
                                    <button
                                       className={`btn ${
                                          !departmentListLocally.length ? "sign-up-button-color-onempty" : "sign-up-button-color"
                                       } text-light w-100 mt-3`}
                                       onClick={handleNextButton}
                                       disabled={!departmentListLocally.length || isloading}
                                    >
                                       {isloading ? "Loading..." : "Next"}
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default AddNewDepartment;
