import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RouteName from "../../../Routes/Routename";
import API from "../../../Api/Api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import axios from "axios";

const CompanyDetail = (props) => {
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const validate = (values) => {
      const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      const cinRegex = /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
      const spaceReg = /^\S.*\S$/;
      const nameRegex = /^[a-zA-Z\s]*$/;
      const errors = {};
      if (!values.fullname) {
         errors.fullname = "Please enter full name";
      } else if (!spaceReg.test(values.fullname)) {
         errors.fullname = "Cannot start & end with a space";
      } else if (!nameRegex.test(values.fullname)) {
         errors.fullname = "Full name can only contain letters and spaces";
      }

      if (!values.cin) {
         errors.cin = "Please enter cin no.";
      } else if (values.cin.length !== 21) {
         errors.cin = "CIN number must be exactly 21 characters long.";
      } else if (!cinRegex.test(values.cin)) {
         errors.cin = "This is not a valid CIN number.";
      }

      if (!values.name) {
         errors.name = "Please enter company name";
      } else if (!spaceReg.test(values.name)) {
         errors.name = "Cannot start & end with a space";
      }
      if (!values.email) {
         errors.email = "Please enter email";
      } else if (!regex.test(values.email)) {
         errors.email = "This is not a valid email address";
      }

      return errors;
   };
   const formik = useFormik({
      initialValues: {
         fullname: "",
         cin: "",
         name: "",
         email: "",
      },
      onSubmit: () => {
         CreateCompany();
      },
      validate,
   });
   const CreateCompany = () => {
      setLoading(true);
      API.Auth.RegisterAPI({
         data: {
            fullName: formik.values.fullname,
            work_email: formik.values.email,
            cin: formik.values.cin,
            organization_name: formik.values.name,
            flag: 1,
         },
      })
         .then((response) => {
            if (response?.data?.statusCode === 200) {
               localStorage.setItem("managementUser", JSON.stringify(response.data.data));

               props.handleNext();
               navigate(`../${RouteName.Auth.Registration}?id=1`, { replace: true });
               setLoading(false);
               // toast.success(response?.data?.message);
            } else {
               toast.error(response?.data?.message);

               setLoading(false);
            }
         })
         .catch((err) => {
            console.log(err);
            setLoading(false);
         });
   };
   return (
      <div className="mt-4">
         <h2 className="textBlue">Company Details</h2>
         <div className="row mt-4">
            <div className="col-10">
               <form>
                  <div className="mb-4">
                     <div className="form-group">
                        <label htmlFor="firstname">
                           {" "}
                           <span className="mandatory-star me-1">*</span> Enter your full name
                        </label>
                        <input
                           type="text"
                           name="fullname"
                           className="form-control mt-2"
                           placeholder="Eg. John Femendes"
                           value={formik.values.fullname}
                           onChange={formik.handleChange}
                        />
                        {formik.errors.fullname && formik.touched.fullname ? (
                           <div className="text-danger">{formik.errors.fullname}</div>
                        ) : null}
                     </div>
                  </div>
                  <div className="mb-4">
                     <div className="form-group">
                        <label htmlFor="phoneno">
                           <span className="mandatory-star me-1">*</span> Enter CIN
                        </label>
                        <input
                           className="form-control mt-2"
                           type="text"
                           name="cin"
                           placeholder="Enter 21 digit CIN"
                           value={formik.values.cin}
                           onChange={formik.handleChange}
                           maxLength={21}
                        />
                        {formik.errors.cin && formik.touched.cin ? <div className="text-danger">{formik.errors.cin}</div> : null}
                     </div>
                  </div>
                  <div className="mb-4">
                     <div className="form-group">
                        <label htmlFor="firstname">
                           {" "}
                           <span className="mandatory-star me-1">*</span>Company Name
                        </label>
                        <input
                           type="text"
                           name="name"
                           className="form-control mt-2"
                           placeholder="Company name"
                           value={formik.values.name}
                           onChange={formik.handleChange}
                        />
                        {formik.errors.name && formik.touched.name ? (
                           <div className="text-danger">{formik.errors.name}</div>
                        ) : null}
                     </div>
                  </div>
                  <div className="mb-4">
                     <div className="form-group">
                        <label htmlFor="Email1">
                           <span className="mandatory-star me-1">*</span> Enter your work email address
                        </label>
                        <input
                           type="email"
                           name="email"
                           id="email"
                           value={formik.values.email}
                           onChange={formik.handleChange}
                           autoComplete="off"
                           placeholder="Enter your E-Mail ID"
                           className="form-control mt-2"
                           aria-describedby="emailHelp"
                        />

                        {formik.errors.email && formik.touched.email ? (
                           <div className="text-danger">{formik.errors.email}</div>
                        ) : null}
                     </div>
                  </div>
                  <div className="text-center">
                     <button
                        type="submit"
                        onClick={formik.handleSubmit}
                        className="btn sign-up-button-color text-white px-5"
                        // onClick={handleNext}
                        disabled={loading}
                     >
                        {loading ? "Please wait..." : "Verify via OTP"}
                     </button>
                  </div>
               </form>
            </div>
         </div>
      </div>
   );
};

export default CompanyDetail;
