/* eslint-disable */
import React, { Suspense } from "react";

import { HashRouter, Route, Routes } from "react-router-dom";
import ConsumerRoutes from "./FinalRoute";
import PageNotFound from "../Components/comman/PageNotFound";

const LOADING = (
  <div className="home-page">
    <div className="container-fluid main-container">
      <div className="row library-row center-me padding-top-40-percent">
        <div className="col-12 center-me">
          <div className="spinner-grow text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);
const Lazy = () => {
  return (
    <HashRouter>
      <Suspense fallback={LOADING}>
        <Routes>
          {ConsumerRoutes?.map((page) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Route key={page.path} {...page} />
          ))}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default Lazy;
