import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// const generateDataset2Data = (allData) => {
//   const dataset2Data = [];

//   allData?.data?.forEach((item) => {
//     const remainingValue = Math.max(0, 100 - item.scoreAverage);
//     dataset2Data.push(remainingValue);
//   });

//   return dataset2Data;
// };

const generateDataset2Data = (allData) => {
  const dataset2Data = [];

  const monthSet = new Set(allData?.data?.map((item) => item.month));

  // Assuming you have an array of all months (January to December)
  const allMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  allMonths.forEach((month) => {
    if (!monthSet.has(month)) {
      // If the month is not present in the data, push 100 to dataset2
      dataset2Data.push(100);
    } else {
      // If the month is present in the data, push the corresponding value from allData
      const matchingItem = allData?.data?.find((item) => item.month === month);
      dataset2Data.push(100 - matchingItem.scoreAverage);
    }
  });

  return dataset2Data;
};

const BarChart = (allData) => {
  const dataset1Data = allData?.data?.map((item) => item?.scoreAverage?.toFixed(2));
  const dataset2Data = generateDataset2Data(allData);
  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Progress",
        data: dataset1Data,
        backgroundColor: "#6993ff",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 0,
      },
      {
        label: "Remaining",
        data: dataset2Data,
        backgroundColor: "#e1e9ff",
        borderColor: "#e1e9ff",
        borderWidth: 0,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 10,
          suggestedMax: 100,
          suggestedMin: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
