import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import editIcon from "../../assets/images/Icons/SVG/edit.svg";
import deleteIcon from "../../assets/images/Icons/SVG/delete.svg";
import departmentIcon from "../../assets/images/Icons/SVG/person-outline.svg";
import successIcon from "../../assets/images/Icons/SVG/success.svg";
import API from "../../Api/Api";
import { useFormik } from "formik";
import RouteName from "../../Routes/Routename";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { paymentData } from "../../Redux/Slice";
import baseApi from "../../Api/config";

const PlanRenewal = () => {
   const [departmentList, setDepartmentList] = useState([]);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);
   const [loading1, setLoading1] = useState(false);
   const [amount, setAmount] = useState();
   const [paymentSuccessModal, setPaymentSuccessModal] = useState(false);
   const [afterPayment, setAfterPayment] = useState(false);
   const [paymentSuccessfulRes, setPaymentSuccessfulRes] = useState();
   const [deletedDepartments, setDeletedDepartments] = useState([]);
   const [allNewUser, setAllNewUser] = useState();   
   const filteredUsers = deletedDepartments.map(user => {
      return {
         fullName: user.fullName,
         dept_name: user.dept_name,
         _id: user._id,
      };
   });
   const [subscriptionDetails, setSubscriptionDetails] = useState(null);
   const [selectedPlanAmountDetails, setselectedPlanAmountDetails] = useState("");
   const [currentDate, setCurrentDate] = useState(new Date());
   const [selectedPlan, setSelectedPlan] = useState("");
   const [isHoveredQuarterly, setIsHoveredQuarterly] = useState(false);
   const [isHoveredYearly, setIsHoveredYearly] = useState(false);
   const [totalPaidAmount, setTotalPaidAmount] = useState(0);
   const [departmentListLocallyPublish, setDepartmentListLocallyPublish] = useState([]);
   const validate = (values) => {
      console.log(values, "value");
      const regex = /^[^\s].*$/;
      const paragraphReg = /^[^\s]+(\s+[^\s]+)*$/;

      const errors = {};
      if (!values.about_us) {
         errors.about_us = "Please enter about us";
      } else if (!regex.test(values.about_us)) {
         errors.about_us = "Cannot start with a space";
      }
      if (!values.mission) {
         errors.mission = "Please enter mission";
      } else if (!paragraphReg.test(values.mission)) {
         errors.mission = "Cannot start with a space";
      }
      if (!values.vision) {
         errors.vision = "Please enter vision";
      } else if (!paragraphReg.test(values.vision)) {
         errors.vision = "Cannot start with a space";
      }
      if (!values.firstKeyFactor) {
         errors.firstKeyFactor = "Please enter firstKeyFactor";
      } else if (!regex.test(values.firstKeyFactor)) {
         errors.firstKeyFactor = "Cannot start with a space";
      }

      if (!values.secondKeyFactor) {
         errors.secondKeyFactor = "Please enter secondKeyFactor";
      } else if (!regex.test(values.secondKeyFactor)) {
         errors.secondKeyFactor = "Cannot start with a space";
      }

      if (!values.thirdKeyFactor) {
         errors.thirdKeyFactor = "Please enter thirdKeyFactor";
      } else if (!regex.test(values.thirdKeyFactor)) {
         errors.thirdKeyFactor = "Cannot start with a space";
      }
      if (!values.purpose) {
         errors.purpose = "Please enter purpose";
      } else if (!paragraphReg.test(values.purpose)) {
         errors.purpose = "Cannot start with a space";
      }
      if (!values.solution) {
         errors.solution = "Please enter solution";
      } else if (!paragraphReg.test(values.solution)) {
         errors.solution = "Cannot start with a space";
      }
      console.log("Erroes", errors);
      return errors;
   };
   const formik = useFormik({
      initialValues: {
         about_us: "",
         mission: "",
         vision: "",
         firstKeyFactor: "",
         secondKeyFactor: "",
         thirdKeyFactor: "",
         purpose: "",
         solution: "",
      },
      onSubmit: (values) => {
         console.log(values, "hello values");
         shareInviteSubmitApi();
      },
      validate,
   });

   const getAllDepartmentList = () => {
      API.PlanRenew.departmentProgressList()
         .then((response) => {
            if (response.data.statusCode === 200) {
               // setDepartmentList(response.data.data);
               const departments = response.data.data.map((department) => ({
                  ...department,
                  isDepartmentActive: false,
               }));

               const activeDepartments = response.data.data.filter((department) => department.is_active === false);
               const newDepartments = response.data.data.filter((department) => department.is_active === true);

               setDeletedDepartments(activeDepartments);
               setAllNewUser(newDepartments.length);
               setDepartmentListLocallyPublish(newDepartments);
               setDepartmentList(departments);
            }
         })
         .catch((err) => {
            if (err.response && err.response.status === 403) {
               navigate("/");
            }
            console.error("API Error:", err);
         });
   };

   useEffect(() => {
      getAllDepartmentList();
   }, []);

   const handleDelete = (id, status) => {
      API.PlanRenew.departmentStatusChange({
         data: {
            _id: id,
            is_active: status == true ? false : true,
         },
      })
         .then((response) => {
            // if (response.data.statusCode === 200) {

            getAllDepartmentList();
            get_Subscription_Details();
            // }
         })
         .catch((error) => {
            console.error("Error deleting department:", error);
         });
   };

   const downloadExcelFile = () => {
      API.PlanRenew.downloadExcelFile({
         data: {
            departments: filteredUsers,
         },
      })
         .then((response) => {
            const fileUrl = `${baseApi.baseurl}/uploads/${response.data.data.xlsxFileName}`;
            const a = document.createElement("a");
            a.href = fileUrl;
            a.style.display = "none";
            a.download = response.data.data.xlsxFileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            toast.success("Report downloaded successfully!");
         })
         .catch((err) => {
            console.error("Error downloading the file:", err);
         });
   };

   //   Payment APIS

   const get_Subscription_Details = () => {
      // if (allNewUser) {
         API.Auth.getSubscriptionDetails(allNewUser)
            .then((response) => {
               if (response.data.statusCode === 200) {
                  setSubscriptionDetails(response.data.data);
               }
            })
            .catch((err) => {
               if (err.response.statusCode === 403) {
                  navigate("/");
               }
               console.log(err);
            });
      // }
   };

   useEffect(() => {
      get_Subscription_Details();
      
   }, [allNewUser]);

   useEffect(() => {
      if (afterPayment) {
         setTimeout(() => {
            setAfterPayment(false);
         }, 3000);
      }
   }, [afterPayment]);
   const showRazorpay = async () => {
      var amountForPayment =
         selectedPlanAmountDetails?.planType === "quaterly"
            ? selectedPlanAmountDetails?.quaterlyCostWithGst
            : selectedPlanAmountDetails?.discountedYearlyCostWithGst;

      try {
         const orderResponse = await API.Auth.createPaymentOrder({
            data: {
               amount: amountForPayment,
            },
         });

         if (orderResponse.data.statusCode === 200) {
            const orderData = orderResponse.data.data;

            const options = {
               key: "rzp_test_BF1ASnSComgPmw",
               amount: orderData?.amount?.toString(),
               currency: "INR",
               order_id: orderData?.razorpay_order_id,
               name: "EasyOKR",
               description: "Thank you for the payment.",
               image: "https://serverqodequay.s3.amazonaws.com/tajurba/profile/1705904286856_img.jpeg",
               handler: function (response) {
                  console.log("Transaction successful:", response);
                  // setTrasactionResponse(response);
                  // You can handle the successful transaction here
                  // Example: Send payment details to your backend

                  API.Auth.createSubscription({
                     data: {
                        // amount: amount,
                        amount: Number(amountForPayment),
                        razorpay_payment_id: response?.razorpay_payment_id,
                        razorpay_order_id: response?.razorpay_order_id,
                        expiry_date: calculateEndDate(),
                        department_array: departmentListLocallyPublish?.map((item) => item?._id),
                        organization_id: departmentListLocallyPublish[0]?.organization_id,
                        subscription_data: {
                           plan_type: selectedPlan,
                           organization_id: departmentListLocallyPublish[0]?.organization_id,
                           payment_detail_id: "",
                           department_array: [],
                           department_count: departmentListLocallyPublish?.length,
                           payment_status: "success",
                           subscription_status: "active",
                           start_date: formatDate(currentDate),
                           end_date: calculateEndDate(),
                           discount_amount: Number(amount),
                           discount_percentage: selectedPlanAmountDetails?.planType === "quaterly" ? 0 : 10,
                        },
                        subscription_type: "renewal",
                     },
                  })
                     .then((createSubscriptionResponse) => {
                        if (createSubscriptionResponse?.data?.statusCode === 200) {
                           setPaymentSuccessModal(true);
                           setAfterPayment(true);
                           setPaymentSuccessfulRes(createSubscriptionResponse?.data);
                           // Store paymentSuccessfulRes in localStorage
                           localStorage.setItem("paymentSuccessfulRes", JSON.stringify(createSubscriptionResponse?.data));
                           dispatch(paymentData(createSubscriptionResponse?.data));

                           setTotalPaidAmount(createSubscriptionResponse.data.data.paid_amount);
                        }
                     })
                     .catch((err) => {
                        toast.error("Subscription Failed");
                        console.error(err);
                        localStorage.removeItem("departmentListLocally");
                     });
               },
               prefill: {
                  name: "Rajat",
                  email: "rajat@rajat.com",
                  phone: "9899999999",
               },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
         }
      } catch (error) {
         console.error("Error:", error);
         alert(error.message);
      }
   };
   const shareInviteSubmitApi = () => {
      API.Auth.ShareInviteSubmitAPI({
         data: {
            about_us: formik.values.about_us,
            mission: formik.values.mission,
            vision: formik.values.vision,
            profileImageUrl: null,
            companyLogoUrl: null,
            differentiator: {
               firstKeyFactor: formik.values.firstKeyFactor,
               secondKeyFactor: formik.values.secondKeyFactor,
               thirdKeyFactor: formik.values.thirdKeyFactor,
            },
            purpose: formik.values.purpose,
            solution: formik.values.solution,
            org_Info_filled: true,
         },
      })
         .then((response) => {
            if (response.data.statusCode === 200) {
               console.log(response, "responseresponse");
               document.querySelector(".btn-close").click();
               navigate("/dashboard");
            }
         })
         .catch((err) => console.log(err));
   };
   const calculateEndDate = () => {
      const endDate = new Date(currentDate); // Create a copy of the current date
      if (selectedPlan === "quaterly") {
         // endDate.setMonth(endDate.getMonth() + 3); // Add 3 months
         endDate.setDate(endDate.getDate() + 90);
      } else if (selectedPlan === "annual") {
         // endDate.setFullYear(endDate.getFullYear() + 1); // Add 1 year
         endDate.setDate(endDate.getDate() + 365);
      }

      const year = endDate.getFullYear();
      const month = ("0" + (endDate.getMonth() + 1)).slice(-2);
      const day = ("0" + endDate.getDate()).slice(-2);

      const formattedEndDate = `${year}/${month}/${day}`;
      return formattedEndDate;
   };

   const formatDate = (date) => {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${year}/${month}/${day}`;
   };
   const handleLogOut = () => {
      localStorage.removeItem("managementToken");
      localStorage.removeItem("EmailID");
      localStorage.removeItem("managementUser");
      localStorage.removeItem("is_subscription_expired");
      localStorage.removeItem("departmentListLocallyPublish");
      document.querySelector("#DeleteDepartemnt").click();
      navigate(RouteName.Auth.Login);
   };

   const shareInviteOnEmail = () => {
      setLoading(true);
      localStorage.removeItem("departmentListLocally");
      API.Auth.ShareInviteAPI({
         data: {
            flag: 5,
         },
      })
         .then((response) => {
            setLoading(false);
            if (response.data.org_user.org_Info_filled === false) {
               document.querySelector("#shareInvite").click();
               handleLogOut();
            } else {
               navigate("/");
            }
         })
         .catch((err) => {
            console.log(err);
            setLoading(false);
         });
   };
   const selectPlanButtonStyleYearly = {
      backgroundColor: isHoveredYearly || selectedPlan === "annual" ? "#6993ff" : "",
      color: isHoveredYearly || selectedPlan === "annual" ? "#fff" : "#6993ff",
      transition: "color 0.2s ease",
   };
   const selectPlanButtonStyleQuarterly = {
      backgroundColor: isHoveredQuarterly || selectedPlan === "quaterly" ? "#6993ff" : "",
      color: isHoveredQuarterly || selectedPlan === "quaterly" ? "#fff" : "#6993ff",
      transition: "color 0.2s ease",
   };

   const generatePaymentInvoice = (event) => {
      event.preventDefault();
      let costPerDepartment;
      setLoading1(true);
      console.log("paymentSuccessfulRes", paymentSuccessfulRes);

      if (selectedPlan === "quaterly") {
         costPerDepartment = 499;
      } else if (selectedPlan === "annual") {
         costPerDepartment = 449;
      }
      const payload = {
         total_paid_amount_without_gst: paymentSuccessfulRes?.subscription_data?.discount_amount,
         gst_amount: paymentSuccessfulRes?.data?.paid_amount - paymentSuccessfulRes?.subscription_data?.discount_amount,
         total_paid_amount_with_gst: totalPaidAmount,
         costPerDepartment: costPerDepartment,
         departments: departmentListLocallyPublish,
      };

      API.Auth.getInvoicePDF(payload)
         .then((response) => {
            console.log(response);
            if (response.data.statusCode) {
             
               const filePath = response.data.filePath;

               if (filePath) {
                  const baseApiUrl = baseApi.baseurl;
                  const fileUrl = `${baseApiUrl}${filePath}`;
                  const fileName = filePath.split("/").pop();

                  const downloadLink = document.createElement("a");
                  downloadLink.href = fileUrl;
                  downloadLink.style.display = "none";
                  downloadLink.download = fileName;
                  downloadLink.target = "_blank";

                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);

                  toast.success("Invoice downloaded successfully!");
               } else {
                  toast.error("Failed to generate the invoice. Missing file path.");
               }
               setLoading1(false);
            } else {
               toast.error("Failed to generate the invoice.");
               setLoading1(false);
            }
         })
         .catch((err) => {
            console.error(err);
            toast.error("Failed to generate the invoice.");
         });
   };

   return (
      <div className="mt-4">
         <div className="row justify-content-center mt-4">
            <div className="col-12">
               <div className="row">
                  <div className="col-lg-6">
                     <div className="navbar-brand-box d-flex">
                        <span className="logo mt-2">
                           <NavLink to="/dashboard" className="logo mt-2" style={{ textDecoration: "none" }}>
                              <h2 className="bannerHeading fs-3 mb-0">
                                 EASY<span className="fs-3">OKR</span>
                              </h2>
                           </NavLink>
                        </span>
                     </div>
                  </div>
               </div>
            </div>

            <div className="col-12 p-5">
               <div className="d-flex align-items-center ps-3 pb-5">
                  {!paymentSuccessModal ?
                  <NavLink to="/dashboard">
                     <div className="backArrow me-2">
                        <i className="fa fa-solid fa-chevron-left textDarkBlack"></i>
                     </div>
                  </NavLink> : null}
                  <h2 className="textDarkBlack fw-normal h4 mb-0">Plan Renewal</h2>
               </div>
               <p className="textGrey ps-3">Renew your plan to resume your OKR journey</p>
               <div className="row m-0">
                  <div className="col-8 ">
                     <div className="emailoOuterContainer p-3">
                        <div className="row mb-2">
                           <div className="col-6">
                              <p className="textGrey">List of your departments</p>
                           </div>
                           {!paymentSuccessModal ? (
                              <div className="col-6">
                                 <NavLink to="/add-new-department" className="btn departmentList float-end">
                                    + Add New
                                 </NavLink>
                              </div>
                           ) : null}
                        </div>
                        <div className="row">
                           {departmentList.length > 0 ? (
                              departmentList.map((item, index) => (
                                 <div className="col-4 mb-2" key={index}>
                                    <div className={`p-2 ${item.is_active !== true ? "departmentListdelete" : "departmentList"}`}>
                                       <div className="d-flex justify-content-between">
                                          <div>
                                             <div className="d-flex align-items-center">
                                                <img src={departmentIcon} alt="department icon" />
                                                <p
                                                   className="mb-0 fw-medium textDarkBlack ms-2"
                                                   style={{ overflowWrap: "break-word" }}
                                                >
                                                   {item.dept_name}
                                                </p>
                                             </div>
                                          </div>

                                          {!paymentSuccessModal ? <>
                                             {item.is_active !== true ? (
                                                <div>
                                                   <NavLink
                                                      className="btn bg-white py-1 textDarkGrey"
                                                      onClick={() => handleDelete(item._id, false)}
                                                   >
                                                      Undo
                                                   </NavLink>
                                                </div>
                                             ) : (
                                                <>
                                                
                                                {departmentList.length !==1?
                                                 <div>
                                                   <img
                                                      src={deleteIcon}
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => handleDelete(item._id, true)}
                                                      alt="Delete Icon"
                                                   />
                                                </div>: null}
                                                </>
                                             )}</> : null}
                                       </div>
                                       <div className="d-flex justify-content-between mt-2">
                                          <div className="mb-3">
                                             <p className="mb-0 fw-medium textGrey" style={{ overflowWrap: "anywhere" }}>
                                                {item.email}
                                             </p>
                                          </div>
                                          {!paymentSuccessModal ? (
                                             <>
                                                {item.is_active !== true ? (
                                                   ""
                                                ) : (
                                                   <NavLink
                                                      className="btn bg-white py-1 textDarkGrey p-0"
                                                      to={`/edit-department/${item._id}`}
                                                   >
                                                      <img src={editIcon} />
                                                   </NavLink>
                                                )}
                                             </>
                                          ) : null}
                                       </div>
                                    </div>
                                 </div>
                              ))
                           ) : (
                              <p>No departments found</p>
                           )}
                        </div>
                     </div>
                     <p className="mt-2">
                        <span className="textBlueLight1"> {filteredUsers.length}</span> departments were removed, download the OKR
                        sheet for these departments{" "}
                        <span className="textBlueLight1" style={{ cursor: "pointer" }} onClick={() => downloadExcelFile()}>
                           OKR.xlsx
                        </span>
                     </p>
                  </div>
                  <div className="col-4">
                     {!paymentSuccessModal ? (
                        <div className="row justify-content-center mt-4">
                           {/* need tobe changed */}

                           <div className="col-xxl-12 col-12">
                              {/* <h2 className="textBlue fw-normal h4">
                                 <span className="textDarkGrey h4 fw-normal"></span> Proceed With Payment
                              </h2> */}
                              {/* <p className="textGrey">Kindly make the payment before you start with OKR journey.</p> */}
                              <div className="row m-0 gap-5">
                                 <div className="col mx-n3 share-invite-right p-0">
                                    <p className="textGrey fw-regular mb-2">Total Department Added</p>
                                    <h3 className="textBlueLight1 fw-normal h3 mb-3">{departmentListLocallyPublish?.length}</h3>
                                    <p className="textGrey fw-regular mb-0">
                                       Total Amount Payable
                                       {/* <img src={greentickIcon} width="17" className="ms-1" /> */}
                                    </p>
                                    <small class="lightGrey mb-2 d-block">
                                       Choose your preferred package and proceed with the payment.
                                    </small>
                                    <b className="textGrey fw-regular mb-0">Inclusive GST (18%)</b>

                                    {/* <div class="list-group" id="list-tab" role="tablist">
              <div class="price-tabs active mb-3">
                   <h4 class="h2 textBlueLight1 fw-regular">₹800 <span className="h4">monthly</span></h4>
                   <small class="textDarkGrey mb-0">The cost for an individual department is ₹100 per month</small>
              </div>
              <div class="price-tabs mb-3">
                   <h4 class="h2 textBlueLight1 fw-regular">₹9000 <span className="h4">yearly</span></h4>
                   <small class="textDarkGrey mb-0">The cost for an individual department is ₹1200 for a year</small>
              </div>
  </div> */}
                                    <div className={`list-group`} id="list-tab" role="tablist">
                                       <div
                                          class={`price-tabs mb-3 textBlueLight1`}
                                          onClick={() => {
                                             setSelectedPlan(subscriptionDetails?.quaterly_plan?.planType);
                                             // setAmount(quarterlyCost);
                                             setAmount(subscriptionDetails?.quaterly_plan?.totalQuaterlyCost);
                                             setselectedPlanAmountDetails(subscriptionDetails?.quaterly_plan);
                                          }}
                                          style={selectPlanButtonStyleQuarterly}
                                          onMouseEnter={() => setIsHoveredQuarterly(true)}
                                          onMouseLeave={() => setIsHoveredQuarterly(false)}
                                       >
                                          <div className="row m-0">
                                             <div className="col-md-9 p-0">
                                                <h4 className="h5 fw-normal">
                                                   {" "}
                                                   Quarterly Plan <small className="small p fs-6">(90 days)</small>
                                                </h4>
                                                <small className="mb-0">
                                                   The cost for an individual department is{" "}
                                                   {/* {costPerDepartment ? costPerDepartment : 100} per
                          month. */}
                                                   {subscriptionDetails?.costPerDepartment} per month.
                                                </small>
                                             </div>
                                             <div className="col-md-3 pe-0">
                                                <h3 className="h4 text-right">
                                                   ₹
                                                   {subscriptionDetails?.quaterly_plan?.totalQuaterlyCost
                                                      ? subscriptionDetails?.quaterly_plan?.totalQuaterlyCost
                                                      : 0}
                                                </h3>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div className={`list-group mt-1`} id="list-tab" role="tablist">
                                       <div
                                          class={`price-tabs mb-3 textBlueLight1`}
                                          onClick={() => {
                                             setSelectedPlan(subscriptionDetails?.annual_plan?.planType);
                                             // setAmount(discountedYearlyCost);
                                             setAmount(subscriptionDetails?.annual_plan?.discountedYearlyCost);
                                             setselectedPlanAmountDetails(subscriptionDetails?.annual_plan);
                                          }}
                                          style={selectPlanButtonStyleYearly}
                                          onMouseEnter={() => setIsHoveredYearly(true)}
                                          onMouseLeave={() => setIsHoveredYearly(false)}
                                       >
                                          <div className="row m-0">
                                             <div className="col-md-9 p-0">
                                                <h4 className="h5 fw-normal">
                                                   {" "}
                                                   Annual Plan{" "}
                                                   <small className="small p fs-6">
                                                      (365 days) with{" "}
                                                      {subscriptionDetails?.annual_plan?.discountPercentage
                                                         ? subscriptionDetails?.annual_plan?.discountPercentage
                                                         : 5}
                                                      % discount
                                                   </small>
                                                </h4>
                                                <small className="mb-0">
                                                   The cost for an individual department is{" "}
                                                   {/* {costPerDepartment * 12} for a year. */}
                                                   {subscriptionDetails?.costPerDepartment * 12} for a year.
                                                </small>
                                             </div>
                                             <div className="col-md-3 pe-0">
                                                <h3 className="h4 text-right">
                                                   ₹
                                                   {subscriptionDetails?.annual_plan?.discountedYearlyCost
                                                      ? subscriptionDetails?.annual_plan?.discountedYearlyCost
                                                      : 0}
                                                </h3>
                                                <h4 className="h6 text-center">
                                                   <del>
                                                      ₹
                                                      {subscriptionDetails?.annual_plan?.totalYearlyCost
                                                         ? subscriptionDetails?.annual_plan?.totalYearlyCost
                                                         : 0}
                                                   </del>
                                                </h4>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div className="col-12 text-center">
                                       {/* Button trigger modal */}
                                       <button
                                          type="button"
                                          className="btn sign-up-button-color text-white px-5 mt-1 w-100 max-width-277 sign-up-button-color-onempty"
                                          // data-bs-toggle="modal"
                                          // data-bs-target="#paymentSuccess"
                                          style={{ backgroundColor: amount ? "#6993ff" : "#acacac" }}
                                          onClick={showRazorpay}
                                          disabled={!amount && departmentList.length==1}
                                       >
                                          {loading ? "Please wait..." : "Proceed to Pay"}
                                       </button>
                                       <button
                                          type="button"
                                          className="btn btn sign-up-button-color text-white px-5 mt-3"
                                          id="shareInvite"
                                          data-bs-toggle="modal"
                                          data-bs-target="#staticBackdrop"
                                       >
                                          Share Invite #
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           {/* need tobe changed  end*/}
                        </div>
                     ) : (
                        <div className="row justify-content-center mt-4">
                           <div className="col-xxl-12 col-12">
                              <div className="row m-0 gap-5">
                                 <div className="col-12 share-invite-right ps-0 pe-0">
                                    {/* <p className="textGrey fw-regular mb-2">Total Department Added</p>
                                    <h3 className="textGrey fw-normal h3 mb-3">{departmentList.length}</h3>
                                    <p className="mb-2 d-block textBlueLight1">
                                       Congratulations! You've activated the{" "}
                                       {paymentSuccessfulRes?.subscription_data?.plan_type.charAt(0).toUpperCase() +
                                          paymentSuccessfulRes?.subscription_data?.plan_type.slice(1)}{" "}
                                       plan:
                                    </p> */}
                                    <div className="d-flex align-items-center">
                                       <p className="textGrey fw-regular mb-0">Total Department Added</p>
                                       <span className="textBlueLight1 fw-normal h3 mb-0 ms-2">{departmentList?.length}</span>
                                    </div>

                                    <p className="textGrey fw-regular mb-0">Total Amount Payable</p>
                                    <span className="lightGrey mb-2 d-block">
                                       Choose your preferred package and proceed with the payment.
                                    </span>
                                    <b className="textGrey fw-regular">Inclusive GST (18%)</b>

                                    <div className="list-group" id="list-tab" role="tablist">
                                       <div class="price-tabs-final mb-3">
                                          <div className="row m-0">
                                             <div className="col-md-9 p-0">
                                                <h4 className="h5 textBlueLight1 fw-normal">
                                                   {" "}
                                                   {paymentSuccessfulRes?.subscription_data?.plan_type.charAt(0).toUpperCase() +
                                                      paymentSuccessfulRes?.subscription_data?.plan_type.slice(1)}{" "}
                                                   Plan{" "}
                                                   <small className="small p fs-6">
                                                      (
                                                      {paymentSuccessfulRes?.subscription_data?.plan_type === "annual"
                                                         ? "365"
                                                         : "90"}{" "}
                                                      days)
                                                   </small>
                                                </h4>
                                                <small className="textBlueLight1 mb-0">
                                                   {/* The cost for an individual department is ₹1200 for a
                          year. */}
                                                   {paymentSuccessfulRes?.subscription_data?.planType === "annual"
                                                      ? "The cost for an individual department is ₹3000 for a year."
                                                      : "The cost for an individual department is ₹250 per month."}
                                                </small>
                                             </div>
                                             <div className="col-md-3 pe-0">
                                                <h3 className="textBlueLight1 h4 text-right">
                                                   ₹{paymentSuccessfulRes?.data?.paid_amount}
                                                </h3>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <p className="mb-2 d-block textDarkGrey">
                                       Download your payment invoice:{" "}
                                       <button
                                          className="textBlueLight1"
                                          style={{
                                             background: "none",
                                             border: "none",
                                             padding: 0,
                                             color: "blue",
                                             // textDecoration: "underline",
                                             // cursor: "pointer",
                                             textDecoration: loading1 ? "none" : "underline", // Remove underline when disabled if needed
                                             cursor: loading1 ? "not-allowed" : "pointer", // Show appropriate cursor
                                          }}
                                          onClick={(event) => generatePaymentInvoice(event)}
                                          disabled={loading1}
                                       >
                                          {loading1 ? "Downloading..." : "Download PDF"}
                                       </button>
                                    </p>
                                    <div className="col-12 text-center">
                                       <button
                                          type="button"
                                          className="btn btn sign-up-button-color text-white px-5 mt-3 w-100 max-width-277"
                                          onClick={(e) => shareInviteOnEmail(e)}
                                       >
                                          {loading ? "Please wait.." : " Next"}
                                       </button>
                                       <button
                                          type="button"
                                          className="btn btn sign-up-button-color text-white px-5 mt-3"
                                          id="shareInvite"
                                          data-bs-toggle="modal"
                                          data-bs-target="#staticBackdrop"
                                       >
                                          Share Invite #
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}

                     {afterPayment && (
                        <>
                           <div className="backdrop">
                              <div
                                 className="login-form-container "
                                 style={{
                                    width: "40%",
                                    height: "40%",
                                    position: "absolute",
                                    top: "30%",
                                    left: "30%",
                                 }}
                              >
                                 <div className="text-center mb-5">
                                    <img src={successIcon} alt="sucess" width="50" height="50" className="mx-auto" />
                                 </div>
                                 <div className="text-center px-4 mt-2">
                                    <h4 className="textDarkBlack flex-100 p mt-3 poppinsSemibold">Payment Successful</h4>
                                    <h4 className="textDarkBlack">Your transaction has been successfully processed.</h4>
                                 </div>
                                 <div className="d-flex justify-content-center">
                                    <button
                                       type="button"
                                       className="btn btn sign-up-button-color text-white px-5 mt-1 w-100 max-width-277"
                                       data-bs-dismiss="modal"
                                       onClick={() => {
                                          setAfterPayment(false);
                                       }}
                                    >
                                       Okay
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </>
                     )}
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default PlanRenewal;
