const RouteName = {
   Auth: {
      SignUp: "/Sign-up",
      Login: "/",
      VerifyOtp: "verify-otp",
      OtpRecent: "recent-otp",
      Registration: "registration",
      SSO: "SSO",
      // NewPassword: "new-password",
      // ForgotPassword: "forgot-password",
      // PasswordReset: "password-reset",
      // LoginSuccessful: "login-successful",
   },
   Department: {
      Department: "/department",
      DepartmentDetails: "/department-details/:id",
      TimelyDelivery: "/timely-delivery/:id",
      Objective: "/objective",
   },

   Dashboard: {
      Dashboard: "/dashboard",
      PlanRenewal: "/plan-renewal",
      AddNewDepartment: "/add-new-department",
      EditDepartment: "/edit-department/:id",
   },
   MyProfile: {
      MyProfile: "/my-profile",
   },
   Organization: {
      Organization: "/organization",
   },
   Settings: {
      Settings: "/settings",
      SettingsDetails: "/settings-details/:id/:tab",
      SettingsDetailsEdit: "/settings-details-edit",
   },

   PageNotFound: "*",
};

export default RouteName;
