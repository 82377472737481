import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./Redux/Store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist'
const root = ReactDOM.createRoot(document.getElementById("root"));

let persistor =persistStore(store)
root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <PersistGate persistor={persistor}>
      <App />

      </PersistGate>
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
