import React, { useState } from "react";
import API from "../../Api/Api";
import { toast } from "react-toastify";
import departmentIcon from "../../assets/images/Icons/SVG/Icon materialpersonoutline.svg";
import baseApi from "../../Api/config";

const AddDeptShareDeptLink = (props) => {
   const PaymentDetailsForInvoice = JSON.parse(localStorage.getItem("PaymentDetailsForInvoice"));
   const [loading, setLoading] = useState(false);
   const [loading1, setLoading1] = useState(false);

   const shareInviteOnEmail = () => {
      setLoading(true);
      API.Auth.ShareInviteAPI({
         data: {
            flag: 5,
         },
      })
         .then((response) => {
            setLoading(false);
            // if (response.data.org_user.org_Info_filled === false) {
            //     const closeButton = document.getElementById("closeUpdateButtonAddDept")
            //     closeButton?.click()
            //     props?.setOpenShareLinkModal(false)
            //     toast.success("Invitation shared successfully")
            // } else {
            //     // navigate("/dashboard");
            // }
            if (response.data.statusCode === 200) {
               props?.setOpenShareLinkModal(false);
               toast.success("Invitation shared successfully");
            } else {
               toast.error("Failed to share the invitation. Please try again.");
            }
         })
         .catch((err) => {
            console.log(err);
            setLoading(false);
         });
   };

   console.log("🎗🎗", props);
   // console.log("🎗🎗", props?.newDeptList1);

   const generatePaymentInvoice = (event) => {
      event.preventDefault();
      let costPerDepartment;
      setLoading1(true);
      console.log("props", props);
      console.log("remainingDaysCost", props.remainingDaysCost);

      // console.log("props?.newDeptList1", props?.newDeptList1);
      // console.log("props?.amount", props?.amount);
      // console.log("PaymentDetailsForInvoice", PaymentDetailsForInvoice);
      // console.log(
      //   "gst_amount: props?.amount- PaymentDetailsForInvoice?.subscription_data?.discount_amount ",
      //   props?.amount -
      //     PaymentDetailsForInvoice?.subscription_data?.discount_amount
      // );

      if (PaymentDetailsForInvoice?.subscription_data?.plan_type === "quaterly") {
         costPerDepartment = 499;
      } else if (PaymentDetailsForInvoice?.subscription_data?.plan_type === "annual") {
         costPerDepartment = 449;
      }

      // const payload = {};

      const payload = {
         total_paid_amount_without_gst: props.remainingDaysCost,
         gst_amount: props?.amount - props.remainingDaysCost,
         // total_paid_amount_without_gst:
         //   PaymentDetailsForInvoice?.subscription_data?.discount_amount,
         // gst_amount:
         //   props?.amount -
         //   PaymentDetailsForInvoice?.subscription_data?.discount_amount ,
         total_paid_amount_with_gst: props?.amount,
         costPerDepartment: costPerDepartment,
         departments: props?.newDeptList1,
      };

      // API.Auth.getInvoicePDF(payload)
      API.Auth.getInvoicePDF(payload)
         .then((response) => {
            console.log(response);
            if (response.data.statusCode) {
               console.log("INVOICE API CALL SUCCESS");

               const filePath = response.data.filePath;

               if (filePath) {
                  const baseApiUrl = baseApi.baseurl;
                  const fileUrl = `${baseApiUrl}${filePath}`;
                  const fileName = filePath.split("/").pop();

                  const downloadLink = document.createElement("a");
                  downloadLink.href = fileUrl;
                  downloadLink.style.display = "none";
                  downloadLink.download = fileName;
                  downloadLink.target = "_blank";

                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);

                  toast.success("Invoice downloaded successfully!");
               } else {
                  console.error("Missing filePath in response data");
                  toast.error("Failed to generate the invoice. Missing file path.");
               }
               setLoading1(false);
            } else {
               setLoading1(false);
               toast.error("Failed to generate the invoice.");
            }
         })
         .catch((err) => {
            console.error(err);
            toast.error("Failed to generate the invoice.");
         });
   };

   console.log("Propsssss", props);
   return (
      <div className="popup-container-dept">
         <div
            className="login-form-container-dept"
            // style={{
            //    width: "auto",
            //    height: "auto",
            //    top: "30%",
            //    position: "absolute",
            // }}
         >
            <div className="row p-4">
               {/* <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog">
                     <div className="modal-content"> */}
               <button
                  type="button"
                  id="closeUpdateButtonAddDept"
                  className="btn-close position-absolute custom-close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                     props?.setOpenShareLinkModal(false);
                  }}
               />

               <div className="row">
                  <h3 className="textDarkBlack mb-2">Share Link</h3>
                  <div className="textGrey fw-regular mb-4 d-block">
                     Paid {props?.amount}/- for {props?.totalDepartmentCount} departments
                  </div>
                  <div className="row d-flex" style={{ gap: "50px" }}>
                     {props?.newDeptList?.map((item, index) => {
                        return (
                           <div key={index} className="setObjectiveListBox row p-3" style={{ width: "45%" }}>
                              <div className="col-2 p-0">
                                 <img src={departmentIcon} className="" alt="department icon" />
                              </div>
                              <div className="col-10 setObjectiveListBox">
                                 <p className="mb-1" style={{ overflowWrap: "break-word" }}>
                                    {item?.dept_name}
                                 </p>
                                 <p className="textBlueLight1 mb-0" style={{ overflowWrap: "break-word" }}>
                                    {item?.dept_email}
                                 </p>
                              </div>
                           </div>
                        );
                     })}
                  </div>
                  <p className="mb-2 d-block textDarkGrey">
                     Download your payment invoice:{" "}
                     <button
                        className="textBlueLight1"
                        style={{
                           background: "none",
                           border: "none",
                           padding: 0,
                           color: "blue",
                           // textDecoration: "underline",
                           // cursor: "pointer",
                           textDecoration: loading1 ? "none" : "underline", // Remove underline when disabled if needed
                           cursor: loading1 ? "not-allowed" : "pointer", // Show appropriate cursor
                        }}
                        onClick={(event) => generatePaymentInvoice(event)}
                        disabled={loading1}
                     >
                        {/* Download PDF */}
                        {loading1 ? "Downloading..." : "Download PDF"}
                     </button>
                  </p>
                  <div className="d-flex justify-content-center py-4">
                     <button
                        type="button"
                        className="btn sign-up-button-color btn-primary text-white px-5 mt-1 w-100 max-width-277"
                        onClick={(e) => shareInviteOnEmail(e)}
                        disabled={loading}
                     >
                        {loading ? "Please wait..." : "Share Invite"}
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
      //       </div>
      //    </div>
      // </div>
   );
};

export default AddDeptShareDeptLink;
