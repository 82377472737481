const useTaskScoreAverage = () => {
  // const getTaskScoreAverage = (inputArray) => {
  //   console.log(inputArray, "input array %%%%%%%%%%%");
  //   const { weightedSum, count } = inputArray.reduce(
  //     (accumulator, currentValue) => {
  //       const weightedScore =
  //         (currentValue.current_score * currentValue.weightage) / 100;
  //       return {
  //         weightedSum: accumulator.weightedSum + weightedScore,
  //         count: accumulator.count + 1,
  //       };
  //     },
  //     { weightedSum: 0, count: 0 }
  //   );
  //   console.log(weightedSum, "weightedSum");
  //   const average = count !== 0 ? weightedSum / count : 0;
  //   console.log(average, "average");
  //   return average;
  // };
  const  getTaskScoreAverage= (inputArray)=> {
    const { weightedSum } = inputArray.reduce(
      (accumulator, currentValue) => {
        const weightedScore =
          (currentValue.current_score * currentValue.weightage) / 100;
        return {
          weightedSum: accumulator.weightedSum + weightedScore,
        };
      },
      { weightedSum: 0 }
    );
    return weightedSum;
  }

  return { getTaskScoreAverage };
};

export default useTaskScoreAverage;
