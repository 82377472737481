import React from "react";
import AppLayout from "../Layout/AppLayout";
import { NavLink } from "react-router-dom";
import settingProfile from "../../assets/images/Icons/SVG/Group 22974.svg";

export default function SettingsDetailsEdit() {
  return (
    <AppLayout>
      <div className="main-content mt-3">
        <div className="page-content mb-5">
          <div className="row welcomeHeading">
            <div className="col-12">
              <div className="d-flex align-items-center ps-3">
                <NavLink className="" to="/settings">
                  <div className="backArrow me-2">
                    <i className="fa fa-solid fa-chevron-left textDark"></i>
                  </div>
                </NavLink>
                <h3 className="mb-0 fw-bold">User Management</h3>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-xl-12">
              <div className="row my-2">
                <div className="col-6">
                  <h3 class="textDarkBlack mb-1 fw-bold">Edit User</h3>
                  <small className="textDarkGrey">Manage your users here</small>
                </div>
                <div className="col-6 text-end">
                  <NavLink
                    to=""
                    type="button"
                    className="btn sign-up-button-color text-white py-1 w-auto px-5"
                  >
                    Save
                  </NavLink>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-3 mb-3">
                  <img src={settingProfile} />
                </div>
                <div className="col-lg-9">
                  <div className="mb-4">
                    <p className="textLightGrey mb-1">Department Name</p>
                    <p className="textDarkBlack">Human Resource</p>
                  </div>
                  <div className="mb-4">
                    <p className="textLightGrey mb-1">
                      Enter department owner's email
                    </p>

                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="deepika.harode@qodequay.com"
                      class="form-control"
                      aria-describedby="emailHelp"
                    ></input>
                  </div>
                  <div className="mb-4">
                    <p className="textLightGrey mb-1">User Role</p>
                    <p className="textDarkBlack">Department Owner</p>
                  </div>
                  <div className="mb-4">
                    <p className="textLightGrey mb-1">Status</p>

                    <label className="switch custom-toggle-switch-with-text">
                      <input type="checkbox" id="togBtn" />
                      <div className="slider round"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
