import { configureStore } from "@reduxjs/toolkit";
import { counterReducer, paymentReducer } from "./Slice";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web
import { persistReducer } from "redux-persist";

import { combineReducers } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  storage,
  // Other configuration options can be added here
};

const rootReducer = combineReducers({
  date: counterReducer,
  payment: paymentReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});
