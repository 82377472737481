import axios from "axios";
import { json } from "react-router-dom";
import baseApi from "./config";

const BaseApi = (props) => {
  // Admin Object
  // const adminObject = JSON.parse(localStorage.getItem("VidyameWebUserData"))
  //   ?.data?.token;

  const adminObject = localStorage.getItem("managementToken");


  // Request Configuration
  const configuration = {
    method: props.method,
    url: props.url,
    // baseURL: `${process.env.REACT_APP_BASE_URL}/vidyame/dev/api`,
    baseURL: baseApi.baseurl,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    tokenRequired: props.tokenRequired,
  };
  // Request Data
  if (props.method === "POST") {
    configuration.data = props.data;
  }

  // Request Params
  if (props.method === "GET") {
    configuration.params = props.params;
  }

  // Request Header Authorization
  if (props.tokenRequired) {
    if (adminObject) {
      configuration.headers.Authorization =
        adminObject && `bearer ${adminObject}`;
    } else {
      configuration.headers.Authorization =
        props.token && `bearer ${props.token}`;
    }
  }

  // Return Request
  return axios(configuration);
};

export default BaseApi;
