/* eslint-disable */
// eslint-disable-next-line react/no-deprecated
import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import BarChart from "./BarChart";
import API from "../../Api/Api";
import { useSelector } from "react-redux";
import Carousel from "react-grid-carousel";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import moment from "moment";

export default function Dashboard() {
   const [isOwlCarouselVisible, setIsOwlCarouselVisible] = useState(false);
   const [DashboardData, setdashboardData] = useState(null);

   const [departmentObjectivesData, setDepartmentObjectivesData] = useState([]);
   const [onChangeDepartmentId, setOnChangeDepartmentId] = useState();
   const { sDate, EDate } = useSelector((state) => state.date);
   const navigate = useNavigate();
   const UsersData = JSON.parse(localStorage.getItem("managementUser"));
   const [selectedObjectiveId, setSelectedObjectiveId] = useState(null);
   const [DeptDropDownList, setDeptDropDownList] = useState([]);
   const [okrDepartmentGrowth, setDepartmentGrowth] = useState();
   const [selectDepartmentIndex, setSelectDepartmentIndex] = useState();
   const [expirationDate1, setExirationDate] = useState();
   // const expirationDate = moment("2024-08-05");

   const expirationDate = moment(expirationDate1?.end_date);
   const today = moment();
   const daysRemaining = expirationDate.diff(today, "days");

   let ExpireDayShown = "";

   if (daysRemaining > 0 && daysRemaining <= 7) {
      ExpireDayShown = (
         <div className="col-6 d-flex justify-content-end align-items-center">
            <h5 className="mb-0">
               Your plan is expiring in{" "}
               <span className="textRed" style={{ fontSize: "0.83rem" }}>
                  {daysRemaining} days
               </span>
            </h5>
            <NavLink to="/plan-renewal" className="btn bg-white textRed ms-3 px-4 py-1" style={{ fontSize: "0.83rem" }}>
               Renew
            </NavLink>
         </div>
      );
   } else if (daysRemaining === 0) {
      ExpireDayShown = (
         <div className="col-6 d-flex justify-content-end align-items-center">
            <h5 className="mb-0">
               Your plan is expiring{"  "}
               <span className="textRed" style={{ fontSize: "0.83rem" }}>
                  Today
               </span>
            </h5>
            <NavLink to="/plan-renewal" className="btn bg-white textRed ms-3 px-4 py-1" style={{ fontSize: "0.83rem" }}>
               Renew
            </NavLink>
         </div>
      );
   } else if (daysRemaining < 0) {
      ExpireDayShown = (
         <div className="col-6 d-flex justify-content-end align-items-center">
            <h5 className="mb-0">
               Your Subscription Plan has already{" "}
               <span className="textRed" style={{ fontSize: "0.83rem" }}>
                  Expired
               </span>
            </h5>
            <NavLink to="/plan-renewal" className="btn bg-white textRed ms-3 px-4 py-1" style={{ fontSize: "0.83rem" }}>
               Renew
            </NavLink>
         </div>
      );
   }

   const getAllDashboardData = () => {
      if (sDate && EDate) {
         API.DashBoard.getDashboardData({ sDate, EDate })
            .then((response) => {
               if (response.data.statusCode === 200) {
                  setdashboardData(response.data.data);
                  setDeptDropDownList(response.data.data?.dept_okr_growth);

                  setIsOwlCarouselVisible(true);
               }
            })
            .catch((err) => {
               if (err.response.status === 403) {
                  navigate("/");
               }
               console.log(err);
            });
      }
   };
   const getExprirationData = () => {
      API.DashBoard.getExprirationDateData()
         .then((response) => {
            if (response.data.statusCode === 200) {
               setExirationDate(response.data.data);
            }
         })
         .catch((err) => {
            if (err.response.status === 403) {
               navigate("/");
            }
            console.log(err);
         });
   };
   useEffect(() => {
      getExprirationData();
   }, []);
   useEffect(() => {
      getAllDashboardData();
   }, [sDate, EDate]);
 

   const [initialized, setInitialized] = useState(false);

   useEffect(() => {
      // Initialize OwlCarousel only once
      if (!initialized) {
         // Add your OwlCarousel initialization code here
         $(".owl-carousel").owlCarousel();
         setInitialized(true);
      }
   }, [initialized]);

   const Link = ({ id, children, title }) => (
      <OverlayTrigger
         overlay={
            <Tooltip id={id}>
               <div
                  className="tooltip-inner-custom"
                  style={{
                     backgroundColor: "#f7f9ff",
                     color: "#000",
                     padding: "10px",
                     borderColor: "#f7f9ff",
                  }}
               >
                  {title}
               </div>
            </Tooltip>
         }
         trigger={title.length > 30 ? "hover" : []}
      >
         <span className="mt-2">{children}</span>
      </OverlayTrigger>
   );

   const TotalOKRper = (DashboardData?.org_objective_list?.overall_obj_score * 100) / 100;

   useEffect(() => {
      if (onChangeDepartmentId) {
         fetchDepartmentData(onChangeDepartmentId);
      } else if (DashboardData?.dept_okr_growth?.length) {
         fetchDepartmentData(DashboardData?.dept_okr_growth[0]?._id);
      }
   }, [onChangeDepartmentId, DashboardData?.dept_okr_growth, selectedObjectiveId, selectDepartmentIndex]);

   const fetchDepartmentData = (departmentId) => {
      API.Auth.GetOrgDepartmentByIdAPIForDashboard({
         data: { departmentId },
      })
         .then((response) => {
            if (response?.data?.statusCode === 200) {
               setDepartmentObjectivesData(response?.data?.data);
            }
         })
         .catch((err) => {
            console.log(err);
         });
   };

   const handleDepartmentChange2 = (e, index) => {
      // console.log(e.target.value);
      //console.log("Selected Index:", index);
      setSelectDepartmentIndex(index);
      setSelectedObjectiveId(e.target.value);
      setOnChangeDepartmentId(e.target.value);
      API.Auth.GetOrgDepartmentByIdAPIForDashboard({
         data: { departmentId: e.target.value },
      })
         .then((response) => {
            if (response?.data?.statusCode === 200) {
               setDepartmentObjectivesData(response?.data?.data);
            }
         })
         .catch((err) => {
            console.log(err);
         });
   };

   useEffect(() => {
      if (!selectedObjectiveId) {
         setSelectedObjectiveId(DashboardData?.dept_okr_growth[0]?._id);
         setDepartmentGrowth(DashboardData?.dept_okr_growth[0]);
      } else {
         // fetchDepartmentData(selectedObjectiveId);
         // API.Auth.GetOrgDepartmentByIdAPIForDashboard({
         //   data: { selectedObjectiveId },
         // })
         //   .then((response) => {
         //     console.log("Fetch departmental Data", response?.data?.data);
         //     if (response?.data?.statusCode === 200) {
         //       setDepartmentObjectivesData(response?.data?.data);
         //     }
         //   })
         //   .catch((err) => {
         //     console.log(err);
         //   });
      }
   }, [selectedObjectiveId, DashboardData, DashboardData?.dept_okr_growth, selectDepartmentIndex]);

   useEffect(() => {
      setSelectedObjectiveId(DashboardData?.dept_okr_growth[selectDepartmentIndex]?._id);
      setDepartmentGrowth(DashboardData?.dept_okr_growth[selectDepartmentIndex]);
   }, [selectDepartmentIndex]);

   const TooltipCustom = (field) => {
      return field && field.length > 35 ? (
         <div lassName="height-30 textBreak" data-toggle="tooltip" title={field} c>
            {field.substring(0, 35)}
            {/* ... */}
         </div>
      ) : (
         <div>{field}</div>
      );
   };
   return (
      <>
         <AppLayout>
            <div className="main-content mt-4">
               <div className="page-content mb-5">
                  <div className="row welcomeHeading align-items-center">
                     <div className="col-6">
                        <h3 className="mb-0 fw-bold">Welcome to {UsersData?.organization_name} Dashboard</h3>
                     </div>
                     {ExpireDayShown && <>{ExpireDayShown}</>}
                  </div>
                  <div className="row mt-4">
                     <div className="col-12">
                        <h4 className="">Organizational Objective</h4>
                     </div>
                  </div>
                  <div className="row bg-light-blue p-3">
                     <div className="col-xl-2 col-lg-3 d-flex justify-content-center">
                        <div>
                           <p className="textBlueLight1 fw-bold">Overall OKR Growth</p>
                           <>
                              <div style={{ width: "150px", height: "150px" }} className="m-3">
                                 <CircularProgressbar
                                    // key={animationKey}
                                    // value={10}
                                    value={DashboardData?.org_objective_list?.overall_obj_score}
                                    text={`${
                                       DashboardData?.org_objective_list?.overall_obj_score === undefined
                                          ? 0
                                          : Math.round(DashboardData?.org_objective_list?.overall_obj_score * 100) / 100
                                    }%`}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                       rotation: 0.0,
                                       strokeLinecap: "butt",
                                       textSize: "20px",
                                       className: "bold-text",
                                       pathTransitionDuration: 0.5,
                                       pathColor: `${
                                          TotalOKRper < 30 ? "red" : TotalOKRper > 30 && TotalOKRper < 60 ? "#ECB159" : "green"
                                       }`,
                                       textColor: `${
                                          TotalOKRper < 30 ? "red" : TotalOKRper > 30 && TotalOKRper < 60 ? "#ECB159" : "green"
                                       }`,
                                       trailColor: "#d6d6d6",
                                       backgroundColor: "#3e98c7",
                                    })}
                                 />
                              </div>
                           </>
                        </div>
                     </div>
                     <div className="col-lg-9 col-12 ms-3">
                        {/* <div className="row"> */}
                        {isOwlCarouselVisible && (
                           <OwlCarousel
                              items={4}
                              slideBy={4}
                              className="owl-theme ms-5 dashboardSlider"
                              dots={false}
                              nav
                              margin={8}
                              responsive={{
                                 0: { items: 1 },
                                 768: { items: 3 },
                                 992: { items: 4 },
                              }}
                           >
                              {DashboardData &&
                                 DashboardData?.org_objective_list &&
                                 DashboardData?.org_objective_list?.result.map((item, index) => {
                                    return (
                                       <div className="col-xl-3 col-md-6 d-flex justify-content-center ps-5" key={index}>
                                          <div
                                             className="p-3 progressCard mb-3"
                                             style={{
                                                height: "200px",
                                                wordBreak: "break-all",
                                                display: "flex",
                                                flexDirection: "column",
                                             }}
                                          >
                                             <span
                                                title={item?.objective_name}
                                                id="t-1"
                                                className="textBlueLight1 fw-bold mb-0 height-30 textBreak  "
                                             >
                                                {TooltipCustom(item?.objective_name)}
                                             </span>

                                             <div className="d-flex justify-content-center align-items-center mx-4 my-4">
                                                <div style={{ width: "100px", height: "100px" }}>
                                                   <CircularProgressbar
                                                      value={10}
                                                      // value={item?.org_percentage_calculation}
                                                      text={`${Math.round(item?.org_percentage_calculation * 100) / 100}%`}
                                                      strokeWidth={10}
                                                      styles={buildStyles({
                                                         rotation: 0.0,
                                                         strokeLinecap: "butt",
                                                         textSize: "20px",
                                                         className: "bold-text",
                                                         pathTransitionDuration: 0.5,

                                                         pathColor: `${
                                                            item?.org_percentage_calculation === 0
                                                               ? "transparent"
                                                               : item?.org_percentage_calculation < 30
                                                               ? "red"
                                                               : item?.org_percentage_calculation > 30 &&
                                                                 item?.org_percentage_calculation < 60
                                                               ? "#ECB159"
                                                               : "green"
                                                         }`,
                                                         textColor: `${
                                                            item?.org_percentage_calculation < 30
                                                               ? "red"
                                                               : item?.org_percentage_calculation > 30 &&
                                                                 item?.org_percentage_calculation < 60
                                                               ? "#ECB159"
                                                               : "green"
                                                         }
                                       `,
                                                         trailColor: "#d6d6d6",
                                                         backgroundColor: "#3e98c7",
                                                      })}
                                                   />
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    );
                                 })}
                           </OwlCarousel>
                        )}
                        {/* </div> */}
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-xl-5">
                        <h4 className="mt-4">Organization Objective Progress</h4>
                        <BarChart data={DashboardData && DashboardData?.org_objective_progress} />
                     </div>
                     <div className="col-xl-7">
                        <div className="row justify-content-center">
                           <div className="col-lg-11">
                              <h4 className="mt-4">Departmental OKR Growth</h4>
                              <div>
                                 {isOwlCarouselVisible && (
                                    <OwlCarousel items={1} className="owl-theme" dots={false} nav margin={8}>
                                       {/* {okrDepartmentGrowth?.objective_list?.map(
                            (item, index) => ( */}

                                       <div>
                                          <div className="row align-items-center departmentHeading mt-2">
                                             <div className="col-6">
                                                <div class="form-group d-flex align-items-center me-5">
                                                   <select
                                                      class="form-select form-control bg-white py-2"
                                                      style={{ width: "230px" }}
                                                      aria-label="Default select example"
                                                      id="definedepartment"
                                                      name="departmentId"
                                                      onChange={(e) => handleDepartmentChange2(e, e.target.selectedIndex)}
                                                      value={onChangeDepartmentId}
                                                   >
                                                      {DeptDropDownList &&
                                                         DeptDropDownList?.map((item, index) => {
                                                            return (
                                                               <option key={item?._id} value={item?._id} data-index={index}>
                                                                  {item?.dept_name.length > 20
                                                                     ? item?.dept_name.slice(0, 20) + "..."
                                                                     : item?.dept_name}{" "}
                                                               </option>
                                                            );
                                                         })}
                                                   </select>
                                                   <h3 className="textBlueLight1 fw-bold ms-3 m-0">
                                                      {okrDepartmentGrowth?.dept_growth
                                                         ? Math.round(okrDepartmentGrowth.dept_growth * 100) / 100
                                                         : 0}
                                                      %
                                                   </h3>
                                                </div>
                                             </div>
                                             <div className="col-6 ">
                                                <div className="d-flex align-items-center justify-content-end">
                                                   <div>
                                                      {/* <h4 className="textBlueLight1 mb-0 fw-bold">
                                                      {item?.dept_name ? item?.dept_name : "-"}
                                                   </h4> */}
                                                      <p className="lightGrey mb-0">
                                                         {okrDepartmentGrowth?.fullName ? okrDepartmentGrowth.fullName : "-"}
                                                      </p>
                                                   </div>
                                                   <img
                                                      src={
                                                         okrDepartmentGrowth?.profileImageUrl
                                                            ? `${process.env.REACT_APP_BASE_URL}/images/${okrDepartmentGrowth.profileImageUrl}`
                                                            : departmentImg
                                                      }
                                                      className="ms-3 swiper-slide-img"
                                                   />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="row departmentCards p-3">
                                             {okrDepartmentGrowth?.objective_list?.length > 3 ? (
                                                <Carousel cols={3} rows={1} gap={10} loop>
                                                   {okrDepartmentGrowth.objective_list.map((objective, i) => (
                                                      <Carousel.Item key={i} className="mx-3">
                                                         <div
                                                            className="progressCard p-3 text-center mb-3 d-flex align-items-center justify-content-center"
                                                            style={{
                                                               height: "140px",
                                                               wordBreak: "break-all",
                                                            }}
                                                         >
                                                            <div>
                                                               <Link
                                                                  title={objective?.objective_name}
                                                                  id="t-1"
                                                                  className="textBlueLight1 fw-bold mb-0"
                                                               >
                                                                  {objective?.objective_name.length > 20
                                                                     ? objective?.objective_name.slice(0, 20) + "..."
                                                                     : objective?.objective_name}
                                                               </Link>
                                                               <h4 className="textBlueLight1 fw-bold mt-2">
                                                                  {Math.round(objective?.org_percentage_calculation * 100) / 100}%
                                                               </h4>
                                                            </div>
                                                         </div>
                                                      </Carousel.Item>
                                                   ))}
                                                </Carousel>
                                             ) : (
                                                <div className="row">
                                                   {okrDepartmentGrowth?.objective_list?.map((objective, i) => (
                                                      <div className="col-3" style={{ wordBreak: "break-all" }} key={i}>
                                                         <div
                                                            className="progressCard p-3 text-center mb-3 d-flex align-items-center justify-content-center"
                                                            style={{ height: "140px" }}
                                                         >
                                                            <div>
                                                               {/* <p className="lightGrey mb-2">
                  {objective?.objective_name}
                </p> */}
                                                               <span
                                                                  title={objective?.objective_name}
                                                                  id="t-1"
                                                                  className="textBlueLight1 fw-bold mb-2"
                                                               >
                                                                  {objective?.objective_name.length > 20
                                                                     ? objective?.objective_name.slice(0, 20) + "..."
                                                                     : objective?.objective_name}
                                                               </span>
                                                               <h4 className="textBlueLight1 fw-bold mt-2">
                                                                  {Math.round(objective?.org_percentage_calculation * 100) / 100}%
                                                               </h4>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   ))}
                                                </div>
                                             )}
                                          </div>
                                       </div>

                                       {/* )
                          )} */}
                                    </OwlCarousel>
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </AppLayout>
      </>
   );
}
