import React from "react";
import nointernetImg from "../../assets/images/Icons/noInternet1.png";

export default function NoInternetConnection() {
  return (
    // <div className="container-fluid">
    //   <div className="row">
    //     <div className="col-12 text-center">
    //       <div className="text-center">
    //         <h1 className="heading-error mt-5">Something went wrong</h1>
    //         <p className="mb-4">Couldn&apos;t fetch the widget.try again</p>
    //         <br />
    //         <div className="Nointernet-img">
    //           <img src={nointernetImg} alt="nointernet" className="img-fluid" />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="container">
      <div className="row justify-content-center align-items-center vh-100">
        <div className="col-md-6 text-center">
          <h1 className="text-center modal-text-que gilroysemibold">
            Something went wrong
          </h1>
          <p className="text-center modal-text-que gilroyregular mb-4">
            Couldn&apos;t fetch the widget.try again
          </p>
          <img
            src={nointernetImg}
            className="img-fluid"
            alt="Image"
            style={{ height: "70px", width: "70px" }}
          />
          <br />
          {/* <button
            type="button"
            className="btn sign-up-button-color text-white gilroysemibold float-center mt-4"
            id="plalist-button-yes"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Close
          </button> */}
        </div>
      </div>
    </div>
  );
}
