import * as Yup from "yup";

export const signUpSchema = Yup.object({
  firstName: Yup.string()
    .min(3, "Please enter valid First Name")
    .max(15, "Please enter valid First Name")
    .matches(/^[a-z A-Z]+$/, "Please enter valid First Name")
    .required("Please enter First Name"),
  lastName: Yup.string()
    .min(3, "Please enter valid Last Name")
    .max(15, "Please enter valid Last Name")
    .matches(/^[a-z A-Z]+$/, "Please enter valid Last Name")
    .required("Please enter Last Name"),
  email: Yup.string()
    .email("Invalid email address. Please enter valid Email Id")
    .required("Please enter Email Id")
    .matches(
      /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
      "Invalid email address. Please enter valid Email Id"
    ),
});

export const LoginSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address. Please enter valid Email Id")
    .required("Please enter Email Id")
    .matches(
      /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
      "Invalid email address. Please enter valid Email Id"
    ),
});
