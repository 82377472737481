/* eslint-disable */
import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { NavLink, useLocation } from "react-router-dom";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
// import API from "../../Api/Api";
import API from "../../Api/Api";
import NodataFound from "../comman/NodataFound";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
export default function Settings() {
   const location = useLocation();

  const [userList, setuserList] = useState(null);
  const [activeList, setactiveList] = useState(null);
  const [inactiveList, setinactiveList] = useState(null);
  const [PendingList, setPendingList] = useState(null);
  const [selectedTab, setSelectedTab] = useState("all");
  const [meetingDetails, setMeetingDetails] = useState(null);
const [loading,setLoading]=useState(false)

  const formik = useFormik({
    initialValues: {
      day: "",
      hours: "",
      minutes: "",
      period: "AM", // Set the default value to "AM"
      timer: "",
    },
    validationSchema: Yup.object({
      day: Yup.string().required("Select a day"),
      hours: Yup.number()
        .min(1, "Hour must be between 1 and 12")
        .max(12, "Hour must be between 1 and 12")
        .required("Enter hours "),
      minutes: Yup.number()
        .min(0, "Minutes must be between 0 and 59")
        .max(59, "Minutes must be between 0 and 59")
        .required(" & minutes"),
      period: Yup.string().required("Select AM/PM"),
      timer: Yup.string().required("Select a timer"), // Validation for timer
    }),
    onSubmit: (values) => {
      createMeetingDetails();
    },
  });
  
  const createMeetingDetails = () => {
    setLoading(true);
        const hours24 =
      formik.values.period === "AM"
        ? formik.values.hours % 12
        : (formik.values.hours % 12) + 12;

      const meetingTime = `${String(hours24).padStart(2, "0")}:${String(formik.values.minutes).padStart(2, "0")}:00`;

      const payload = {
         meeting_day: formik.values.day,
         meeting_time: meetingTime,
         time_slot_hours_before_meeting: parseInt(formik.values.timer),
      };
      console.log("payload--", payload);

      API.scoreManagement

         .saveMeetingDetails(payload)

         .then((response) => {
            console.log("API Response:", response);

        if (response.data.statusCode === 200) {
          console.log("API Call Successful");
          toast.success("Meeting details saved successfully!");
        } else {
          toast.error("Failed to save meeting details.");
        }
    setLoading(false);

      })
      .catch((err) => {
    setLoading(false);

        toast.error("Failed to save meeting details.");
      });
  };

   // -------------
   // const getUsersList = () => {
   //   if (selectedTab === "all") {
   //     API.userManagement
   //       .GetDepartmentListByStatusAPI({
   //         data: {
   //           all_users: true,
   //         },
   //       })
   //       .then((response) => {
   //         console.log(response);
   //         setuserList(response?.data?.data);
   //       })
   //       .catch((err) => console.log(err));
   //   } else if (selectedTab === "active") {
   //     API.userManagement
   //       .GetDepartmentListByStatusAPI({
   //         data: {
   //           active_users: true,
   //         },
   //       })
   //       .then((response) => {
   //         console.log(response);
   //         const filteredActiveData = response?.data?.data?.filter(
   //           (item) => item?.profileSetup === true && item?.joined === true
   //         );
   //         setactiveList(filteredActiveData);
   //       })
   //       .catch((err) => console.log(err));
   //   } else if (selectedTab === "inactive") {
   //     API.userManagement
   //       .GetDepartmentListByStatusAPI({
   //         data: {
   //           inactive_users: true,
   //         },
   //       })
   //       .then((response) => {
   //         console.log(response);
   //         setinactiveList(response?.data?.data);
   //       })
   //       .catch((err) => console.log(err));
   //   } else if (selectedTab === "pending") {
   //     API.userManagement
   //       .GetDepartmentListByStatusAPI({
   //         data: {
   //           pending_users: true,
   //         },
   //       })
   //       .then((response) => {
   //         console.log(response);
   //         setPendingList(response?.data?.data);
   //       })
   //       .catch((err) => console.log(err));
   //   }
   // };

   const getUsersList = () => {
      if (selectedTab === "all") {
         API.userManagement
            .GetDepartmentListByStatusAPI({
               data: {
                  all_users: true,
               },
            })
            .then((response) => {
               if (response.status >= 200 && response.status < 300) {
                  console.log("Success:", response);
                  setuserList(response?.data?.data);
               } else {
                  console.error("Failed response:", response);
               }
            })
            .catch((err) => {
               if (err.response.status === 403) {
                  navigate("/");
               }
               console.log(err);
            });
      } else if (selectedTab === "active") {
         API.userManagement
            .GetDepartmentListByStatusAPI({
               data: {
                  active_users: true,
               },
            })
            .then((response) => {
               if (response.status >= 200 && response.status < 300) {
                  console.log("Success:", response);
                  const filteredActiveData = response?.data?.data?.filter(
                     (item) => item?.profileSetup === true && item?.joined === true
                  );
                  setactiveList(filteredActiveData);
               } else {
                  console.error("Failed response:", response);
               }
            })
            .catch((err) => console.error("Error:", err));
      } else if (selectedTab === "inactive") {
         API.userManagement
            .GetDepartmentListByStatusAPI({
               data: {
                  inactive_users: true,
               },
            })
            .then((response) => {
               if (response.status >= 200 && response.status < 300) {
                  console.log("Success:", response);
                  setinactiveList(response?.data?.data);
               } else {
                  console.error("Failed response:", response);
               }
            })
            .catch((err) => console.error("Error:", err));
      } else if (selectedTab === "pending") {
         API.userManagement
            .GetDepartmentListByStatusAPI({
               data: {
                  pending_users: true,
               },
            })
            .then((response) => {
               if (response.status >= 200 && response.status < 300) {
                  console.log("Success:", response);
                  setPendingList(response?.data?.data);
               } else {
                  console.error("Failed response:", response);
               }
            })
            .catch((err) => console.error("Error:", err));
      }
   };

   useEffect(() => {
      // Fetch initial data when the component mounts
      getUsersList();
   }, [selectedTab]); // Add selectedTab to the dependency array

   // AM and PM Logic
   const formatMeetingPeroid = (meetingTime) => {
      if (!meetingTime) return "";
      const [hour, minute] = meetingTime.split(":");
      const hourNum = parseInt(hour, 10);
      const period = hourNum >= 12 ? "PM" : "AM";
      return `${period}`;
   };

   // Hours Logic
   const formatMeetingTime = (meetingTime) => {
      if (!meetingTime) return "";

      const [hour, minute, second] = meetingTime.split(":");
      let hourNum = parseInt(hour, 10);
      const period = hourNum >= 12 ? "PM" : "AM";

      // Convert hour to 12-hour format
      hourNum = hourNum % 12 || 12; // '|| 12' handles the case when hourNum is 0

      return `${hourNum}`;
   };

  const getMeetingDetails = () => {
    API.scoreManagement
      .loadMeetingDetails()
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log("Response is here:", response.data.data);
          // setMeetingDetails(response.data.data);
          const initialData = response.data.data;
          // Update form data state with fetched data
          // Optionally update formik initial values
          formik.setValues({
            day: initialData.meeting_day,
            // hours: initialData.meeting_time.split(":")[0],
            hours: formatMeetingTime(initialData.meeting_time),
            minutes: initialData.meeting_time.split(":")[1],
            // period: initialData.meeting_time.split(":")[2].substring(2),
            period: initialData.meeting_time ?formatMeetingPeroid(initialData.meeting_time) :"AM",
            timer: initialData.time_slot_hours_before_meeting >=12 ? `${initialData.time_slot_hours_before_meeting} hours`: "",
          });
        } else {
          console.log("Unexpected status code:", response.data.statusCode);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data.statusCode === 403) {
          navigate("/");
        } else {
          console.log("Error response:", err.response);
          console.log("Error message:", err.message);
        }
      });
  };

   useEffect(() => {
      getMeetingDetails();
   }, []);

   const handleTabClick = (tabName) => {
      setSelectedTab(tabName);
   };

   // console.log("👓👓", location);
   // console.log("Component rendered");
   // console.log("day--", formik.values.day);
   // console.log("hours--", formik.values.hours);
   // console.log("minutes--", formik.values.minutes);
   // console.log("period--", formik.values.period);
   // console.log("timer--", formik.values.timer);

   return (
      <>
         <AppLayout>
            <div className="main-content mt-4">
               <div className="page-content mb-5">
                  <div className="row welcomeHeading">
                     <div className="col-12">
                        <h3 className="mb-0 fw-bold">Settings</h3>
                     </div>
                  </div>

                  <ul className="nav nav-tabs nav-tabs-custom mt-4 border-0" id="mainTab" role="tablist">
                     <li className="nav-item">
                        <a className="nav-link active ps-0" data-bs-toggle="tab" href="#User" role="tab">
                           <h3 class="fw-bold">User Management</h3>
                        </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link " data-bs-toggle="tab" href="#Score" role="tab">
                           <h3 class="fw-bold">Score Management</h3>
                        </a>
                     </li>
                  </ul>
                  <div className="tab-content text-muted mt-3">
                     <div className="tab-pane active" id="User" role="tabpanel">
                        <div className="row mt-4">
                           <div className="col-12">
                              <ul className="nav nav-tabs nav-tabs-custom mt-sm-5 mt-3 mt-xl-0" role="tablist">
                                 <li className="nav-item">
                                    <a
                                       className={`nav-link active`}
                                       data-bs-toggle="tab"
                                       href="#All"
                                       role="tab"
                                       onClick={() => handleTabClick("all")}
                                    >
                                       <span>All Users</span>
                                    </a>
                                 </li>
                                 <li className="nav-item">
                                    <a
                                       className={`nav-link ${location.state === "Active" ? "active" : ""}`}
                                       data-bs-toggle="tab"
                                       href="#Active"
                                       role="tab"
                                       onClick={() => handleTabClick("active")}
                                    >
                                       <span>Active Users</span>
                                    </a>
                                 </li>
                                 <li className="nav-item">
                                    <a
                                       className={`nav-link ${location.state === "Inactive" ? "active" : ""}`}
                                       data-bs-toggle="tab"
                                       href="#Inactive"
                                       role="tab"
                                       onClick={() => handleTabClick("inactive")}
                                    >
                                       <span>Inactive Users</span>
                                    </a>
                                 </li>
                                 <li className="nav-item">
                                    <a
                                       className={`nav-link ${location.state === "Pending" ? "active" : ""}`}
                                       data-bs-toggle="tab"
                                       href="#Pending"
                                       role="tab"
                                       onClick={() => handleTabClick("pending")}
                                    >
                                       <span>Pending Users</span>
                                    </a>
                                 </li>
                              </ul>
                              <div className="tab-content text-muted mt-3">
                                 <div className="tab-pane active" id="All" role="tabpanel">
                                    {userList?.length > 0 ? (
                                       <div className="row mt-4">
                                          {userList &&
                                             userList?.map((item, index) => {
                                                return (
                                                   <div className="col-xl-4 mb-3" key={index}>
                                                      <NavLink
                                                         className="text-decoration-none"
                                                         to={`/settings-details/${item?._id}/All`}
                                                      >
                                                         <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
                                                            <div className="d-flex align-items-center">
                                                               <img
                                                                  src={
                                                                     item?.profileImageUrl
                                                                        ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                                                        : departmentImg
                                                                  }
                                                                  className="me-3 Organization-img"
                                                               />
                                                               <div>
                                                                  <h3 className="textDarkBlack mb-1 fw-bold msm">
                                                                     {item?.dept_name}
                                                                  </h3>
                                                                  <p className="lightGrey mb-0">
                                                                     {item?.fullName ? item?.fullName : "-"}
                                                                  </p>
                                                               </div>
                                                            </div>
                                                            {item?.isDepartmentActive === true && item?.joined === true ? (
                                                               <p className="mb-0 activeStatus">Active</p>
                                                            ) : item?.isDepartmentActive === false ? (
                                                               <p className="mb-0 inactiveStatus">Inactive</p>
                                                            ) : item?.isDepartmentActive === true || item?.joined === false ? (
                                                               <p className="mb-0 pendingStatus">Pending</p>
                                                            ) : (
                                                               ""
                                                            )}
                                                            {/* {item?.isDepartmentActive !== true ? (
                                      <p className="mb-0 pendingStatus">
                                        Pending
                                      </p>
                                    ) : item?.isDepartmentActive === false ? (
                                      <p className="mb-0 inactiveStatus">
                                        Inactive
                                      </p>
                                    ) : (
                                      <p className="mb-0 activeStatus">
                                        Active
                                      </p>
                                    )} */}
                                                         </div>
                                                      </NavLink>
                                                   </div>
                                                );
                                             })}
                                       </div>
                                    ) : (
                                       <NodataFound />
                                    )}
                                 </div>
                                 <div className="tab-pane" id="Active" role="tabpanel">
                                    {activeList?.length > 0 ? (
                                       <div className="row mt-4">
                                          {activeList &&
                                             activeList?.map((item, index) => {
                                                return (
                                                   <div className="col-xl-4 mb-3" key={index}>
                                                      <NavLink
                                                         className="text-decoration-none"
                                                         to={`/settings-details/${item?._id}/Active`}
                                                      >
                                                         <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
                                                            <div className="d-flex align-items-center">
                                                               <img
                                                                  src={
                                                                     item?.profileImageUrl
                                                                        ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                                                        : departmentImg
                                                                  }
                                                                  className="me-3 Organization-img"
                                                               />

                                                               <div>
                                                                  <h3 className="textDarkBlack mb-1 fw-bold">{item.dept_name}</h3>
                                                                  <p className="lightGrey mb-0">
                                                                     {item?.fullName ? item?.fullName : "-"}
                                                                  </p>
                                                               </div>
                                                            </div>
                                                            <p className="mb-0 activeStatus">Active</p>
                                                         </div>
                                                      </NavLink>
                                                   </div>
                                                );
                                             })}
                                       </div>
                                    ) : (
                                       <NodataFound />
                                    )}
                                 </div>
                                 <div className="tab-pane" id="Inactive" role="tabpanel">
                                    {inactiveList?.length > 0 ? (
                                       <div className="row mt-4">
                                          {inactiveList &&
                                             inactiveList.map((item, index) => {
                                                return (
                                                   <div className="col-xl-4 mb-3" key={index}>
                                                      <NavLink
                                                         className="text-decoration-none"
                                                         to={`/settings-details/${item?._id}/Inactive`}
                                                      >
                                                         <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
                                                            <div className="d-flex align-items-center">
                                                               <img
                                                                  src={
                                                                     item?.profileImageUrl
                                                                        ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                                                        : departmentImg
                                                                  }
                                                                  className="me-3 Organization-img"
                                                               />
                                                               <div>
                                                                  <h3 className="textDarkBlack mb-1 fw-bold">
                                                                     {item?.dept_name}
                                                                  </h3>
                                                                  <p className="lightGrey mb-0">
                                                                     {item?.fullName ? item?.fullName : "-"}
                                                                  </p>
                                                               </div>
                                                            </div>
                                                            <p className="mb-0 inactiveStatus">Inactive</p>
                                                         </div>
                                                      </NavLink>
                                                   </div>
                                                );
                                             })}
                                       </div>
                                    ) : (
                                       <NodataFound />
                                    )}
                                 </div>
                                 <div className="tab-pane" id="Pending" role="tabpanel">
                                    {PendingList?.length > 0 ? (
                                       <div className="row mt-4">
                                          {PendingList &&
                                             PendingList.map((item, index) => {
                                                return (
                                                   <div className="col-xl-4 mb-3" key={index}>
                                                      <NavLink
                                                         className="text-decoration-none"
                                                         to={`/settings-details/${item?._id}/Pending`}
                                                      >
                                                         <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
                                                            <div className="d-flex align-items-center">
                                                               <img
                                                                  src={
                                                                     item?.profileImageUrl
                                                                        ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                                                        : departmentImg
                                                                  }
                                                                  className="me-3 Organization-img"
                                                               />
                                                               <div>
                                                                  <h3 className="textDarkBlack mb-1 fw-bold">
                                                                     {item?.dept_name}
                                                                  </h3>
                                                                  <p className="lightGrey mb-0">
                                                                     {item?.fullName ? item?.fullName : "-"}
                                                                  </p>
                                                               </div>
                                                            </div>
                                                            <p className="mb-0 pendingStatus">Pending</p>
                                                         </div>
                                                      </NavLink>
                                                   </div>
                                                );
                                             })}
                                       </div>
                                    ) : (
                                       <NodataFound />
                                    )}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="tab-pane" id="Score" role="tabpanel">
                        <div className="row">
                           <form>
                              <div className="col-8">
                                 <div className="row mt-4">
                                    <div className="col-12">
                                       <h4 className="textDarkBlack fw-bold">Schedule Meeting Time</h4>
                                       <p className="textLightGrey">Select date and time for your weekly meeting</p>
                                       <div className="row mb-3 mt-4">
                                          <div className="col-6">
                                             <label className="form-label textLightGrey">Select Day</label>
                                             <select
                                                className="form-select border-radius-2 w-60"
                                                aria-label="Default select example"
                                                name="day"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.day}
                                             >
                                                <option value="">Select</option>
                                                <option value="Monday">Monday</option>
                                                <option value="Tuesday">Tuesday</option>
                                                <option value="Wednesday">Wednesday</option>
                                                <option value="Thursday">Thursday</option>
                                                <option value="Friday">Friday</option>
                                                <option value="Saturday">Saturday</option>
                                                <option value="Sunday">Sunday</option>
                                             </select>
                                             {formik.touched.day && formik.errors.day ? (
                                                <div className="text-danger">{formik.errors.day}</div>
                                             ) : null}
                                          </div>
                                          <div className="col-6">
                                             <label className="form-label textLightGrey">Select Time</label>
                                             <div
                                                className="form-control border-0 py-2 px-4 d-flex align-items-center"
                                                style={{ width: "fit-content" }}
                                             >
                                                <div className="d-flex align-items-center me-3">
                                                   <input
                                                      type="text"
                                                      maxLength="2"
                                                      className="border-0 p-1 me-1 outline-0"
                                                      placeholder="00"
                                                      style={{
                                                         background: "#F2F3F6",
                                                         width: "30px",
                                                      }}
                                                      name="hours"
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      value={formik.values.hours}
                                                   />
                                                   :
                                                   <input
                                                      type="text"
                                                      maxLength="2"
                                                      className="border-0 p-1 ms-1"
                                                      placeholder="00"
                                                      style={{
                                                         background: "#F2F3F6",
                                                         width: "30px",
                                                      }}
                                                      name="minutes"
                                                      onChange={formik.handleChange}
                                                      onBlur={formik.handleBlur}
                                                      value={formik.values.minutes}
                                                   />
                                                </div>

                                <div className="d-flex align-items-center">
                                  <select
                                    name="period"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.period}
                                    style={{
                                      background: "#F2F3F6",
                                      border: "none",
                                    }}
                                  >
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                  </select>
                                </div>
                              </div>
                              <div style={{display:"flex"}}>
                              {formik.touched.hours && formik.errors.hours ? (
                                <div className="text-danger">
                                  {formik.errors.hours}
                                </div>
                              ) : null}
                              {formik.touched.minutes &&
                              formik.errors.minutes ? (
                                <div className="text-danger">
                                 &nbsp; {formik.errors.minutes}
                                </div>
                              ) : null}

                              {formik.touched.period && formik.errors.period ? (
                                <div className="text-danger">
                                  {formik.errors.period}
                                </div>
                              ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12">
                          <h4 className="textDarkBlack fw-bold">Set Timer</h4>
                          <p className="textLightGrey">
                            Select timer to update weekly score prior to the
                            scheduled meeting
                          </p>
                          <div className="row mb-3 mt-4">
                            <div className="col-6">
                              <label className="form-label textLightGrey">
                                Select Timer
                              </label>
                              <select
                                className="form-select border-radius-2 w-60"
                                aria-label="Default select example"
                                name="timer"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.timer}
                              >
                                <option value="">Select</option>
                                <option value="12 hours">12 hours</option>
                                <option value="24 hours">24 hours</option>
                                <option value="36 hours">36 hours</option>
                                <option value="48 hours">48 hours</option>
                                <option value="60 hours">60 hours</option>
                              </select>
                              {formik.touched.timer && formik.errors.timer ? (
                                <div className="text-danger">
                                  {formik.errors.timer}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn sign-up-button-color text-white px-5 mt-3"
                            //  data-bs-toggle="modal"
                            //  data-bs-target="#staticBackdrop"
                            onClick={formik.handleSubmit}
                            disabled={loading}

                          >
                          {loading ?"Please wait..." :"Save"}
                          </button>

                                       <div
                                          className="modal fade"
                                          id="staticBackdrop"
                                          data-bs-backdrop="static"
                                          data-bs-keyboard="false"
                                          tabIndex={-1}
                                          aria-labelledby="staticBackdropLabel"
                                          aria-hidden="true"
                                       >
                                          <div
                                             className="modal-dialog modal-dialog-centered justify-content-center"
                                             style={{ background: "transparent" }}
                                          >
                                             <div className="modal-content border-0 p-5 border-radius w-auto">
                                                <div className="modal-body text-center">
                                                   <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="92"
                                                      height="92"
                                                      viewBox="0 0 92 92"
                                                   >
                                                      <g
                                                         id="Group_23160"
                                                         data-name="Group 23160"
                                                         transform="translate(-1588 -541)"
                                                      >
                                                         <circle
                                                            id="Ellipse_671"
                                                            data-name="Ellipse 671"
                                                            cx="46"
                                                            cy="46"
                                                            r="46"
                                                            transform="translate(1588 541)"
                                                            fill="#fff"
                                                         />
                                                         <path
                                                            id="Path_19269"
                                                            data-name="Path 19269"
                                                            d="M47,1A46,46,0,1,0,93,47,46,46,0,0,0,47,1ZM70.866,37.411,41.593,66.684a4.182,4.182,0,0,1-5.913,0L23.134,54.138a4.182,4.182,0,1,1,5.914-5.914l9.588,9.589L64.953,31.5a4.182,4.182,0,1,1,5.914,5.914Z"
                                                            transform="translate(1587 540)"
                                                            fill="#39b742"
                                                         />
                                                      </g>
                                                   </svg>
                                                   <h4 className="textDarkBlack fw-bold my-3">Updated</h4>
                                                   <p className="textDarkBlack mb-3">
                                                      Your score management has been successfully updated
                                                   </p>
                                                   <NavLink to="" className="btn sign-up-button-color text-white px-5">
                                                      Okay
                                                   </NavLink>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </AppLayout>
      </>
   );
}
