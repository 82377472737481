import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import RouteName from "../../Routes/Routename";
import notificationImg from "../../assets/images/Icons/Pics/avtar.png";
import { yearData } from "../../Redux/Slice";
import moment from "moment";
import API from "../../Api/Api";
import { toast } from "react-toastify";
function AppLayout(children) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

 const [is_subscription_expired,setIsSubscriptionExpired] =useState();

   const getExprirationData = () => {
      API.DashBoard.getExprirationDateData()
         .then((response) => {
            if (response.data.statusCode === 200) {
              setIsSubscriptionExpired(response.data.data.is_subscription_expired);
            }
         })
         .catch((err) => {
            if (err.response.status === 403) {
               navigate("/");
            }
            console.log(err);
         });
   };
   useEffect(() => {
      getExprirationData();
   }, []);

  // console.log("is_subscription_expired", is_subscription_expired);

  console.log("location", location);
  // const token=localStorage.getItem("managementToken");
  const handleLogOut = () => {
    localStorage.removeItem("managementToken");
    localStorage.removeItem("EmailID");
    localStorage.removeItem("managementUser");
    localStorage.removeItem("is_subscription_expired");
    document.querySelector("#DeleteDepartemnt").click();
    navigate(RouteName.Auth.Login);
  };
  const ButtonSVG = () => {
    <svg
      id="Component_29_13"
      data-name="Component 29 – 13"
      width="46"
      height="46"
      viewBox="0 0 46 46"
    >
      <rect
        id="Rectangle_8366"
        data-name="Rectangle 8366"
        width="46"
        height="46"
        rx="10"
        fill="#eaf0ff"
      />
      <g
        id="Icon_ionic-ios-notifications-outline"
        data-name="Icon ionic-ios-notifications-outline"
        transform="translate(7.225 8.07)"
      >
        <path
          id="Path_16915"
          data-name="Path 16915"
          d="M20.37,28.336a.911.911,0,0,0-.893.717,1.762,1.762,0,0,1-.352.766,1.329,1.329,0,0,1-1.132.415,1.351,1.351,0,0,1-1.132-.415,1.762,1.762,0,0,1-.352-.766.911.911,0,0,0-.893-.717h0a.917.917,0,0,0-.893,1.118,3.142,3.142,0,0,0,3.27,2.609,3.136,3.136,0,0,0,3.27-2.609.92.92,0,0,0-.893-1.118Z"
          transform="translate(-2.443 -6.132)"
          fill="#0a1b46"
        />
        <path
          id="Path_16916"
          data-name="Path 16916"
          d="M24.131,19.226c-.847-1.048-2.513-1.662-2.513-6.355,0-4.816-2.265-6.752-4.377-7.217-.2-.046-.341-.108-.341-.3V5.2a1.307,1.307,0,0,0-1.32-1.27h-.033a1.307,1.307,0,0,0-1.32,1.27v.15c0,.191-.143.258-.341.3-2.117.47-4.377,2.4-4.377,7.217,0,4.693-1.666,5.3-2.513,6.355a1.022,1.022,0,0,0,.874,1.642h15.4A1.022,1.022,0,0,0,24.131,19.226Zm-2.144.3H9.164a.225.225,0,0,1-.181-.377,6.218,6.218,0,0,0,1.155-1.724,11.058,11.058,0,0,0,.786-4.553,7.541,7.541,0,0,1,1.149-4.481A3.541,3.541,0,0,1,14.2,6.966a1.959,1.959,0,0,0,1.023-.542.454.454,0,0,1,.654-.01,2.028,2.028,0,0,0,1.034.552,3.541,3.541,0,0,1,2.128,1.425,7.541,7.541,0,0,1,1.149,4.481,11.058,11.058,0,0,0,.786,4.553,6.294,6.294,0,0,0,1.182,1.75A.212.212,0,0,1,21.987,19.526Z"
          transform="translate(0 0)"
          fill="#0a1b46"
        />
      </g>
    </svg>;
  };

  // date logic

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedQuarter, setSelectedQuarter] = useState("Annual");
  const [yearsFilterData, setYearsFilterData] = useState(null);
  const getAllYearsFilters = () => {
    API.DashBoard.getDepartmentYears()
      .then((res) => {
        if (res.data.statusCode === 200) {
          setYearsFilterData(res.data.data);
          setSelectedYear(res?.data?.data[0]?.year);
          setSelectedQuarter(res.data.data[0]?.quarters[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllYearsFilters();
  }, []);

  const getQuarterDates = (year, quarter) => {
    const startMonth = (quarter - 1) * 3 + 1;
    const startDate = moment(new Date(year, startMonth - 1, 1)).format(
      "YYYY-MM-DD"
    );
    const endDate = moment(new Date(year, startMonth + 2, 0)).format(
      "YYYY-MM-DD"
    );
    return { startDate, endDate };
  };

  useEffect(() => {
    if (selectedQuarter !== undefined) {
      if (selectedQuarter !== "Annual") {
        const { startDate, endDate } = getQuarterDates(
          selectedYear,
          selectedQuarter.slice(-1)
        );

        const newdata = {
          sDate: startDate,
          EDate: endDate,
        };
        dispatch(yearData(newdata));
      } else {
        const startDate = moment(new Date(selectedYear, 0, 1)).format(
          "YYYY-MM-DD"
        ); // January 1st
        const endDate = moment(new Date(selectedYear, 11, 31)).format(
          "YYYY-MM-DD"
        ); // December 31st
        const newdata = {
          sDate: startDate,
          EDate: endDate,
        };
        console.log(newdata, "anumal");

        dispatch(yearData(newdata));
      }
    }
  }, [selectedYear, selectedQuarter]);

  const handleClick = (path) => {
    if (!is_subscription_expired) {
      navigate(path);
    } else {
      toast.warning("Subscription is required");
    }
  };

  return (
    <>
      <body data-layout="detached" data-topbar="colored">
        <div className="container-fluid">
          <div id="layout-wrapper">
            <header id="page-topbar">
              <div className="navbar-header">
                <div className="container-fluid">
                  <div className="row top-header pt-3">
                    <div className="col-lg-6">
                      <div className="navbar-brand-box d-flex">
                        <span className="logo logo-light mt-2">
                          <a
                            href="#/dashboard"
                            className="logo logo-light mt-2"
                            style={{ textDecoration: "none" }}
                          >
                            <h2 className="bannerHeading fs-3 mb-0">
                              EASY<span className="fs-3">OKR</span>
                            </h2>
                          </a>
                          {/* <h2 className="bannerHeading fs-3 mb-0">
                            EASY<span className=" fs-3">OKR</span>
                          </h2> */}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div id="profile-section-div">
                        <div className="d-sm-flex justify-content-end position-relative">
                          {location.pathname === "/dashboard" ? (
                            <div className="d-flex justify-content-end headerSelect">
                              <select
                                className="form-select w-auto me-3 formSelect"
                                aria-label="Select Year"
                                value={selectedYear}
                                onChange={(e) =>
                                  setSelectedYear(parseInt(e.target.value))
                                }
                              >
                                {yearsFilterData &&
                                  yearsFilterData.map((item) => (
                                    <option key={item.year} value={item.year}>
                                      {item.year}
                                    </option>
                                  ))}
                              </select>
                              <select
                                className="form-select w-auto me-3 formSelect"
                                aria-label="Select Quarter"
                                value={selectedQuarter}
                                onChange={(e) =>
                                  setSelectedQuarter(e.target.value)
                                }
                              >
                                {yearsFilterData &&
                                  yearsFilterData
                                    .find((item) => item.year === selectedYear)
                                    ?.quarters.map((quarter, index) => (
                                      <option key={index} value={quarter}>
                                        {quarter}
                                      </option>
                                    ))}
                              </select>
                            </div>
                          ) : null}
                          <div className="d-flex justify-content-end">
                            <div className="notificatiDropdown dropdown d-inline-block">
                              <button
                                type="button"
                                className="btn waves-effect p-0 me-3"
                                id="page-header-user-dropdown"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <ButtonSVG />
                              </button>
                              <div className=" dropdown-menu dropdown-menu-end">
                                <div className="avtarProfile">
                                  <div className="notificationData">
                                    <p className="redText mb-1 fw-bold">
                                      Today
                                    </p>
                                    <div className="row text1 mb-2">
                                      <div className="col-2">
                                        <img
                                          src={notificationImg}
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="col-10">
                                        <p className="darkGrey mb-0">
                                          Abhimanyu
                                        </p>
                                        <small className="notificationTime">
                                          2 mins ago
                                        </small>
                                        <p className="defaultgrey mb-1">
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit
                                        </p>
                                        <div className="notificationTime">
                                          <small className="float-end">
                                            3:24 PM
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row text1 mb-2">
                                      <div className="col-2">
                                        <img
                                          src={notificationImg}
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="col-10">
                                        <p className="darkGrey mb-0">
                                          Abhimanyu
                                        </p>
                                        <small className="notificationTime">
                                          2 mins ago
                                        </small>
                                        <p className="defaultgrey mb-1">
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit
                                        </p>
                                        <div className="notificationTime">
                                          <small className="float-end">
                                            3:24 PM
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="notificationData mt-4">
                                    <p className="redText mb-1 fw-bold">
                                      Yesterday
                                    </p>
                                    <div className="row text1 mb-2">
                                      <div className="col-2">
                                        <img
                                          src={notificationImg}
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="col-10">
                                        <p className="darkGrey mb-0">
                                          Abhimanyu
                                        </p>
                                        <small className="notificationTime">
                                          2 mins ago
                                        </small>
                                        <p className="defaultgrey mb-1">
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit
                                        </p>
                                        <div className="notificationTime">
                                          <small className="float-end">
                                            3:24 PM
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row text1 mb-2">
                                      <div className="col-2">
                                        <img
                                          src={notificationImg}
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="col-10">
                                        <p className="darkGrey mb-0">
                                          Abhimanyu
                                        </p>
                                        <small className="notificationTime">
                                          2 mins ago
                                        </small>
                                        <p className="defaultgrey mb-1">
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit
                                        </p>
                                        <div className="notificationTime">
                                          <small className="float-end">
                                            3:24 PM
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="myProfile">
                              <NavLink
                                to="/my-profile"
                                type="button"
                                className="btn p-0"
                              >
                                {location.pathname !== "/my-profile" ? (
                                  <svg
                                    id="Component_28_13"
                                    data-name="Component 28 – 13"
                                    width="46"
                                    height="46"
                                    viewBox="0 0 46 46"
                                  >
                                    <rect
                                      id="Rectangle_8365"
                                      data-name="Rectangle 8365"
                                      width="46"
                                      height="46"
                                      rx="10"
                                      fill="#eaf0ff"
                                    />
                                    <path
                                      id="Icon_material-person-outline"
                                      data-name="Icon material-person-outline"
                                      d="M14.614,8.046a2.261,2.261,0,1,1-2.261,2.261,2.261,2.261,0,0,1,2.261-2.261m0,9.691c3.2,0,6.568,1.572,6.568,2.261v1.184H8.046V20c0-.689,3.37-2.261,6.568-2.261M14.614,6a4.307,4.307,0,1,0,4.307,4.307A4.306,4.306,0,0,0,14.614,6Zm0,9.691C11.739,15.691,6,17.134,6,20v3.23H23.229V20C23.229,17.134,17.489,15.691,14.614,15.691Z"
                                      transform="translate(8 8)"
                                      fill="#0a1b46"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    id="Component_28_13"
                                    data-name="Component 28 – 13"
                                    width="46"
                                    height="46"
                                    viewBox="0 0 46 46"
                                  >
                                    <rect
                                      id="Rectangle_8365"
                                      data-name="Rectangle 8365"
                                      width="46"
                                      height="46"
                                      rx="10"
                                      fill="#6993ff"
                                    />
                                    <path
                                      id="Icon_material-person-outline"
                                      data-name="Icon material-person-outline"
                                      d="M14.614,8.046a2.261,2.261,0,1,1-2.261,2.261,2.261,2.261,0,0,1,2.261-2.261m0,9.691c3.2,0,6.568,1.572,6.568,2.261v1.184H8.046V20c0-.689,3.37-2.261,6.568-2.261M14.614,6a4.307,4.307,0,1,0,4.307,4.307A4.306,4.306,0,0,0,14.614,6Zm0,9.691C11.739,15.691,6,17.134,6,20v3.23H23.229V20C23.229,17.134,17.489,15.691,14.614,15.691Z"
                                      transform="translate(8 8)"
                                      fill="#fff"
                                    />
                                  </svg>
                                )}
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="vertical-menu">
              {/* <div className="h-100"> */}
              <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                  <li>
                    <NavLink to="/dashboard" className="waves-effect p-lg-1">
                      <a
                        href="#"
                        className={
                          location.pathname === "/dashboard"
                            ? "activeSideTab waves-effect"
                            : "waves-effect"
                        }
                      >
                        <span className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 22.112 22.115"
                          >
                            <path
                              id="Path_11488"
                              data-name="Path 11488"
                              d="M21.761,12.106Q16.78,7.127,11.8,2.144a1.619,1.619,0,0,0-.482-.351H10.8a1.628,1.628,0,0,0-.487.351Q5.335,7.127.352,12.108A1.593,1.593,0,0,0,0,12.593v.517a.912.912,0,0,0,.912.621.882.882,0,0,0,.547-.226c.057-.049.093-.128.184-.14a.488.488,0,0,1,.023.254q.009,4.607,0,9.212a1.046,1.046,0,0,0,.623,1.077H19.818a1.049,1.049,0,0,0,.624-1.078c-.007-3.07,0-6.141,0-9.212a.478.478,0,0,1,.038-.279.922.922,0,0,0,1.632-.229v-.518a1.578,1.578,0,0,0-.351-.486m-8.2,9.79c0,.211-.08.269-.28.269-.745-.006-1.5,0-2.245,0h0c-.732,0-1.466-.01-2.2,0-.205,0-.279-.06-.279-.27q.01-2.245,0-4.49c0-.211.08-.27.28-.27q2.223.01,4.447,0c.2,0,.278.062.278.271q-.01,2.245,0,4.49"
                              transform="translate(0 -1.793)"
                            />
                          </svg>
                        </span>
                        <div className="hideText">
                          <span className="poppinsRegular text-white">
                            Home
                          </span>
                        </div>
                      </a>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={!is_subscription_expired ? "/organization" : null}
                      onClick={() => {
                        handleClick("/organization");
                      }}
                      className="p-lg-1"
                    >
                      <a
                        href="#"
                        className={
                          location.pathname === "/organization"
                            ? "activeSideTab waves-effect"
                            : "waves-effect"
                        }
                      >
                        <span className="">
                          {location.pathname === "/organization" ? (
                            <svg
                              id="Group_22518"
                              data-name="Group 22518"
                              width="25"
                              height="25"
                              viewBox="0 0 16.454 16.449"
                            >
                              <defs>
                                <clipPath id="clip-path">
                                  <rect
                                    id="Rectangle_8453"
                                    data-name="Rectangle 8453"
                                    width="16.454"
                                    height="16.449"
                                    fill="#95b3ff"
                                  />
                                </clipPath>
                              </defs>
                              <g id="Group_22289" data-name="Group 22289">
                                <path
                                  id="Path_17055"
                                  data-name="Path 17055"
                                  d="M0,7.656c.042-.29.074-.582.128-.87a8.081,8.081,0,0,1,3.6-5.435A8.014,8.014,0,0,1,8.685.02a7.791,7.791,0,0,1,1.829.313.523.523,0,0,1,.4.647.521.521,0,0,1-.675.346A7.19,7.19,0,0,0,2.872,3.438,6.906,6.906,0,0,0,1.1,7.231,7.017,7.017,0,0,0,2.752,12.89,6.885,6.885,0,0,0,6.8,15.279a6.994,6.994,0,0,0,6.07-1.569,6.98,6.98,0,0,0,2.4-4.063,7.168,7.168,0,0,0-.12-3.351.885.885,0,0,1-.036-.36.486.486,0,0,1,.472-.4.5.5,0,0,1,.526.383A11.577,11.577,0,0,1,16.37,7.1a7.809,7.809,0,0,1-.476,4.1,8.1,8.1,0,0,1-6.224,5.13c-.287.058-.579.086-.869.129H7.645c-.19-.025-.38-.046-.569-.074a8.239,8.239,0,0,1-6.949-6.7C.075,9.394.042,9.1,0,8.812V7.656"
                                  transform="translate(0 -0.008)"
                                />
                                <path
                                  id="Path_17056"
                                  data-name="Path 17056"
                                  d="M65.057,58.661c.424.068.906.133,1.381.227a.508.508,0,0,1,.41.649.524.524,0,0,1-.676.346,4.639,4.639,0,1,0,3.382,5.276,4.721,4.721,0,0,0-.085-1.987.521.521,0,0,1,.215-.6.488.488,0,0,1,.608.049.679.679,0,0,1,.175.3,5.677,5.677,0,0,1-4.771,7.08,5.667,5.667,0,0,1-1.816-11.181c.367-.068.741-.1,1.176-.152"
                                  transform="translate(-56.764 -56.144)"
                                />
                                <path
                                  id="Path_17057"
                                  data-name="Path 17057"
                                  d="M180.059,8.74a.515.515,0,0,1-.4-.833,1.335,1.335,0,0,1,.122-.128c.874-.874,1.742-1.755,2.628-2.618a.981.981,0,0,0,.323-.675c.052-.7.13-1.395.2-2.092a.7.7,0,0,1,.212-.453q.874-.864,1.738-1.737a.527.527,0,0,1,.939.355c.035.544.086,1.088.123,1.632.008.118.057.151.168.159.566.039,1.131.084,1.7.129a.492.492,0,0,1,.449.334.5.5,0,0,1-.129.559c-.51.512-1.034,1.011-1.528,1.538a1.375,1.375,0,0,1-.98.487c-.693.039-1.384.124-2.075.2a.42.42,0,0,0-.23.117q-1.419,1.409-2.829,2.826a.554.554,0,0,1-.42.2m3.7-4.21c.585-.057,1.149-.118,1.713-.164a.571.571,0,0,0,.409-.208,5.357,5.357,0,0,1,.385-.387c.111-.1.231-.2.348-.3l-.042-.059c-.344-.026-.688-.054-1.032-.077a.556.556,0,0,1-.589-.584c-.013-.192-.024-.384-.039-.576-.012-.156-.03-.312-.048-.507-.31.312-.593.6-.872.882a.234.234,0,0,0-.045.13q-.046.39-.085.781c-.035.35-.067.7-.1,1.065"
                                  transform="translate(-171.836 0)"
                                />
                                <path
                                  id="Path_17058"
                                  data-name="Path 17058"
                                  d="M122.82,126.175a3.087,3.087,0,0,1-.41-6.145,3.7,3.7,0,0,1,.767-.011.5.5,0,0,1,.445.42.49.49,0,0,1-.255.526.692.692,0,0,1-.352.064,2.057,2.057,0,0,0-2.231,1.894,2.053,2.053,0,1,0,4.1.171.511.511,0,0,1,.708-.492.491.491,0,0,1,.318.46,3.086,3.086,0,0,1-2.645,3.068c-.148.018-.3.029-.446.043"
                                  transform="translate(-114.605 -114.855)"
                                />
                              </g>
                            </svg>
                          ) : (
                            <svg
                              id="Group_22518"
                              data-name="Group 22518"
                              width="25"
                              height="25"
                              viewBox="0 0 16.454 16.449"
                            >
                              <defs>
                                <clipPath id="clip-path">
                                  <rect
                                    id="Rectangle_8453"
                                    data-name="Rectangle 8453"
                                    width="16.454"
                                    height="16.449"
                                  />
                                </clipPath>
                              </defs>
                              <g id="Group_22289" data-name="Group 22289">
                                <path
                                  id="Path_17055"
                                  data-name="Path 17055"
                                  d="M0,7.656c.042-.29.074-.582.128-.87a8.081,8.081,0,0,1,3.6-5.435A8.014,8.014,0,0,1,8.685.02a7.791,7.791,0,0,1,1.829.313.523.523,0,0,1,.4.647.521.521,0,0,1-.675.346A7.19,7.19,0,0,0,2.872,3.438,6.906,6.906,0,0,0,1.1,7.231,7.017,7.017,0,0,0,2.752,12.89,6.885,6.885,0,0,0,6.8,15.279a6.994,6.994,0,0,0,6.07-1.569,6.98,6.98,0,0,0,2.4-4.063,7.168,7.168,0,0,0-.12-3.351.885.885,0,0,1-.036-.36.486.486,0,0,1,.472-.4.5.5,0,0,1,.526.383A11.577,11.577,0,0,1,16.37,7.1a7.809,7.809,0,0,1-.476,4.1,8.1,8.1,0,0,1-6.224,5.13c-.287.058-.579.086-.869.129H7.645c-.19-.025-.38-.046-.569-.074a8.239,8.239,0,0,1-6.949-6.7C.075,9.394.042,9.1,0,8.812V7.656"
                                  transform="translate(0 -0.008)"
                                />
                                <path
                                  id="Path_17056"
                                  data-name="Path 17056"
                                  d="M65.057,58.661c.424.068.906.133,1.381.227a.508.508,0,0,1,.41.649.524.524,0,0,1-.676.346,4.639,4.639,0,1,0,3.382,5.276,4.721,4.721,0,0,0-.085-1.987.521.521,0,0,1,.215-.6.488.488,0,0,1,.608.049.679.679,0,0,1,.175.3,5.677,5.677,0,0,1-4.771,7.08,5.667,5.667,0,0,1-1.816-11.181c.367-.068.741-.1,1.176-.152"
                                  transform="translate(-56.764 -56.144)"
                                />
                                <path
                                  id="Path_17057"
                                  data-name="Path 17057"
                                  d="M180.059,8.74a.515.515,0,0,1-.4-.833,1.335,1.335,0,0,1,.122-.128c.874-.874,1.742-1.755,2.628-2.618a.981.981,0,0,0,.323-.675c.052-.7.13-1.395.2-2.092a.7.7,0,0,1,.212-.453q.874-.864,1.738-1.737a.527.527,0,0,1,.939.355c.035.544.086,1.088.123,1.632.008.118.057.151.168.159.566.039,1.131.084,1.7.129a.492.492,0,0,1,.449.334.5.5,0,0,1-.129.559c-.51.512-1.034,1.011-1.528,1.538a1.375,1.375,0,0,1-.98.487c-.693.039-1.384.124-2.075.2a.42.42,0,0,0-.23.117q-1.419,1.409-2.829,2.826a.554.554,0,0,1-.42.2m3.7-4.21c.585-.057,1.149-.118,1.713-.164a.571.571,0,0,0,.409-.208,5.357,5.357,0,0,1,.385-.387c.111-.1.231-.2.348-.3l-.042-.059c-.344-.026-.688-.054-1.032-.077a.556.556,0,0,1-.589-.584c-.013-.192-.024-.384-.039-.576-.012-.156-.03-.312-.048-.507-.31.312-.593.6-.872.882a.234.234,0,0,0-.045.13q-.046.39-.085.781c-.035.35-.067.7-.1,1.065"
                                  transform="translate(-171.836 0)"
                                />
                                <path
                                  id="Path_17058"
                                  data-name="Path 17058"
                                  d="M122.82,126.175a3.087,3.087,0,0,1-.41-6.145,3.7,3.7,0,0,1,.767-.011.5.5,0,0,1,.445.42.49.49,0,0,1-.255.526.692.692,0,0,1-.352.064,2.057,2.057,0,0,0-2.231,1.894,2.053,2.053,0,1,0,4.1.171.511.511,0,0,1,.708-.492.491.491,0,0,1,.318.46,3.086,3.086,0,0,1-2.645,3.068c-.148.018-.3.029-.446.043"
                                  transform="translate(-114.605 -114.855)"
                                />
                              </g>
                            </svg>
                          )}
                        </span>
                        <div className="hideText">
                          <span className="poppinsRegular text-white">
                            Organization
                          </span>
                        </div>
                      </a>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      // to="/department"
                      to={!is_subscription_expired ? "/department" : null}
                      onClick={() => {
                        handleClick("/department");
                      }}
                      className="p-lg-1"
                    >
                      <a
                        href="#"
                        className={
                          location.pathname === "/department" ||
                          location.pathname.startsWith("/department-details") ||
                          location.pathname.startsWith("/timely-delivery")
                            ? "activeSideTab waves-effect"
                            : "waves-effect"
                        }
                      >
                        <span className="">
                          {location.pathname === "/department" ? (
                            <svg
                              id="dept"
                              width="80"
                              height="46"
                              viewBox="0 0 101 46"
                            >
                              <defs>
                                <clipPath id="clip-path">
                                  <rect
                                    id="Rectangle_8454"
                                    data-name="Rectangle 8454"
                                    width="36.491"
                                    height="27.691"
                                  />
                                </clipPath>
                              </defs>
                              <rect
                                id="Rectangle_8561"
                                data-name="Rectangle 8561"
                                width="80"
                                height="46"
                                opacity="0"
                              />
                              <g
                                id="Group_22290"
                                data-name="Group 22290"
                                transform="translate(32.255 9.154)"
                              >
                                <g id="Group_22290-2" data-name="Group 22290">
                                  <path
                                    id="Path_17059"
                                    data-name="Path 17059"
                                    d="M0,18.683A1.61,1.61,0,0,1,1.81,17.53c.828.036,1.66.008,2.546.008v-.4q0-1.55,0-3.1c0-.734.229-.955,1-.955q5.844,0,11.687,0h.475V10.135H17.13c-.855,0-1.71.009-2.565-.009a1.361,1.361,0,0,1-1.374-1.352q-.024-3.705,0-7.411A1.332,1.332,0,0,1,14.526.015q3.723-.029,7.446,0A1.333,1.333,0,0,1,23.3,1.369q.023,3.652,0,7.3a1.365,1.365,0,0,1-1.381,1.383c-.843.019-1.686.008-2.53.01H18.98v3.021H30.967c.119,0,.238,0,.356,0,.576.034.807.271.81.855.006,1.057,0,2.114,0,3.171v.422c.887,0,1.739,0,2.59,0a3,3,0,0,1,.6.031A1.465,1.465,0,0,1,36.487,19q.008,3.617,0,7.233a1.369,1.369,0,0,1-1.379,1.424q-3.687.03-7.375,0a1.357,1.357,0,0,1-1.37-1.319,3.516,3.516,0,0,1-.021-.355q0-3.385,0-6.77a1.454,1.454,0,0,1,1.088-1.624,2.465,2.465,0,0,1,.6-.052c.878-.005,1.755,0,2.661,0v-2.99H18.977v2.99h.378c.831,0,1.663-.008,2.494.005A1.383,1.383,0,0,1,23.3,18.964q.021,3.652,0,7.3a1.342,1.342,0,0,1-1.367,1.392q-3.687.03-7.375,0a1.343,1.343,0,0,1-1.373-1.386q-.022-3.67,0-7.34A1.369,1.369,0,0,1,14.6,17.546c.96-.015,1.921,0,2.909,0V14.533H5.8v3h.877c.641,0,1.283-.006,1.924,0A1.413,1.413,0,0,1,10.139,19c.032.592.012,1.187.012,1.781,0,1.746,0,3.492,0,5.238a1.454,1.454,0,0,1-1.07,1.6,2.9,2.9,0,0,1-.706.059c-2.162,0-4.324-.023-6.485.015A1.746,1.746,0,0,1,0,26.594Zm8.7.331H1.443v7.219H8.7Zm13.168-.007H14.628v7.232h7.241ZM14.617,8.582l7.255.141V1.437H14.617ZM35.052,18.977H27.8v7.152h7.255Z"
                                    transform="translate(0 0)"
                                  />
                                </g>
                              </g>
                            </svg>
                          ) : (
                            <svg
                              id="dept"
                              width="80"
                              height="46"
                              viewBox="0 0 101 46"
                            >
                              <defs>
                                <clipPath id="clip-path">
                                  <rect
                                    id="Rectangle_8454"
                                    data-name="Rectangle 8454"
                                    width="36.491"
                                    height="27.691"
                                  />
                                </clipPath>
                              </defs>
                              <rect
                                id="Rectangle_8561"
                                data-name="Rectangle 8561"
                                width="80"
                                height="46"
                                opacity="0"
                              />
                              <g
                                id="Group_22290"
                                data-name="Group 22290"
                                transform="translate(32.255 9.154)"
                              >
                                <g id="Group_22290-2" data-name="Group 22290">
                                  <path
                                    id="Path_17059"
                                    data-name="Path 17059"
                                    d="M0,18.683A1.61,1.61,0,0,1,1.81,17.53c.828.036,1.66.008,2.546.008v-.4q0-1.55,0-3.1c0-.734.229-.955,1-.955q5.844,0,11.687,0h.475V10.135H17.13c-.855,0-1.71.009-2.565-.009a1.361,1.361,0,0,1-1.374-1.352q-.024-3.705,0-7.411A1.332,1.332,0,0,1,14.526.015q3.723-.029,7.446,0A1.333,1.333,0,0,1,23.3,1.369q.023,3.652,0,7.3a1.365,1.365,0,0,1-1.381,1.383c-.843.019-1.686.008-2.53.01H18.98v3.021H30.967c.119,0,.238,0,.356,0,.576.034.807.271.81.855.006,1.057,0,2.114,0,3.171v.422c.887,0,1.739,0,2.59,0a3,3,0,0,1,.6.031A1.465,1.465,0,0,1,36.487,19q.008,3.617,0,7.233a1.369,1.369,0,0,1-1.379,1.424q-3.687.03-7.375,0a1.357,1.357,0,0,1-1.37-1.319,3.516,3.516,0,0,1-.021-.355q0-3.385,0-6.77a1.454,1.454,0,0,1,1.088-1.624,2.465,2.465,0,0,1,.6-.052c.878-.005,1.755,0,2.661,0v-2.99H18.977v2.99h.378c.831,0,1.663-.008,2.494.005A1.383,1.383,0,0,1,23.3,18.964q.021,3.652,0,7.3a1.342,1.342,0,0,1-1.367,1.392q-3.687.03-7.375,0a1.343,1.343,0,0,1-1.373-1.386q-.022-3.67,0-7.34A1.369,1.369,0,0,1,14.6,17.546c.96-.015,1.921,0,2.909,0V14.533H5.8v3h.877c.641,0,1.283-.006,1.924,0A1.413,1.413,0,0,1,10.139,19c.032.592.012,1.187.012,1.781,0,1.746,0,3.492,0,5.238a1.454,1.454,0,0,1-1.07,1.6,2.9,2.9,0,0,1-.706.059c-2.162,0-4.324-.023-6.485.015A1.746,1.746,0,0,1,0,26.594Zm8.7.331H1.443v7.219H8.7Zm13.168-.007H14.628v7.232h7.241ZM14.617,8.582l7.255.141V1.437H14.617ZM35.052,18.977H27.8v7.152h7.255Z"
                                    transform="translate(0 0)"
                                  />
                                </g>
                              </g>
                            </svg>
                          )}
                        </span>
                        <div className="hideText">
                          <span className="poppinsRegular text-white iconTop">
                            Department
                          </span>
                        </div>
                      </a>
                    </NavLink>
                  </li>
                </ul>
                <ul className="metismenu list-unstyled">
                  <li>
                    <NavLink
                      onClick={() => {
                        handleClick("/settings");
                      }}
                      // to="/settings"
                      to={!is_subscription_expired ? "/settings" : null}
                      className="p-lg-1"
                    >
                      <a
                        href="#/settings"
                        // href={is_subscription_expired ? "#/settings" : null}
                        className={
                          location.pathname === "/settings" ||
                          location.pathname.startsWith("/settings-details")
                            ? "activeSideTab waves-effect"
                            : "waves-effect"
                        }
                      >
                        <span className="">
                          <svg
                            id="setting"
                            width="80"
                            height="46"
                            viewBox="0 0 101 46"
                          >
                            <rect
                              id="Rectangle_8563"
                              data-name="Rectangle 8563"
                              width="101"
                              height="46"
                              opacity="0"
                            />
                            <g
                              id="Icon_feather-settings"
                              data-name="Icon feather-settings"
                              transform="translate(34.5 6.5)"
                            >
                              <path
                                id="Path_17053"
                                data-name="Path 17053"
                                d="M22.5,18A4.5,4.5,0,1,1,18,13.5,4.5,4.5,0,0,1,22.5,18Z"
                                transform="translate(-1.5 -1.5)"
                              />
                              <path
                                id="Path_17054"
                                data-name="Path 17054"
                                d="M26.591,20.591a2.25,2.25,0,0,0,.45,2.482l.082.082a2.729,2.729,0,1,1-3.859,3.859l-.082-.082a2.268,2.268,0,0,0-3.845,1.609v.232a2.727,2.727,0,1,1-5.455,0V28.65a2.25,2.25,0,0,0-1.473-2.059,2.25,2.25,0,0,0-2.482.45l-.082.082a2.729,2.729,0,1,1-3.859-3.859l.082-.082a2.268,2.268,0,0,0-1.609-3.845H4.227a2.727,2.727,0,1,1,0-5.455H4.35a2.25,2.25,0,0,0,2.059-1.473,2.25,2.25,0,0,0-.45-2.482l-.082-.082A2.729,2.729,0,1,1,9.736,5.986l.082.082a2.25,2.25,0,0,0,2.482.45h.109a2.25,2.25,0,0,0,1.364-2.059V4.227a2.727,2.727,0,0,1,5.455,0V4.35a2.268,2.268,0,0,0,3.845,1.609l.082-.082a2.729,2.729,0,1,1,3.859,3.859l-.082.082a2.25,2.25,0,0,0-.45,2.482v.109a2.25,2.25,0,0,0,2.059,1.364h.232a2.727,2.727,0,1,1,0,5.455H28.65a2.25,2.25,0,0,0-2.059,1.364Z"
                              />
                            </g>
                          </svg>
                        </span>
                        <div className="hideText">
                          <span className="poppinsRegular text-white iconTop">
                            Settings
                          </span>
                        </div>
                      </a>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="p-lg-1"
                      data-bs-toggle="modal"
                      id="DeleteDepartemnt"
                      data-bs-target="#staticBackdrop1"
                    >
                      <a
                        href="#"
                        className={
                          location.pathname === "/"
                            ? "activeSideTab waves-effect"
                            : "waves-effect"
                        }
                      >
                        <span className="">
                          {location.pathname === "/" ? (
                            <svg
                              id="Hover_logout"
                              data-name="Hover logout"
                              width="80"
                              height="46"
                              viewBox="0 0 101 46"
                            >
                              <rect
                                id="Rectangle_8564"
                                data-name="Rectangle 8564"
                                width="101"
                                height="46"
                                opacity="0"
                              />
                              <g
                                id="_3994382_access_close_exit_logout_sign_out_icon"
                                data-name="3994382_access_close_exit_logout_sign out_icon"
                                transform="translate(36 8)"
                              >
                                <path
                                  id="Path_17515"
                                  data-name="Path 17515"
                                  d="M6.4,33.375H20.625a1.875,1.875,0,1,0,0-3.75H6.4L8.826,27.2A1.875,1.875,0,1,0,6.174,24.55L.549,30.175a1.874,1.874,0,0,0,0,2.651l5.625,5.625A1.875,1.875,0,1,0,8.826,35.8Z"
                                  transform="translate(0 -16.5)"
                                />
                                <path
                                  id="Path_17516"
                                  data-name="Path 17516"
                                  d="M52.875,0h-15A1.874,1.874,0,0,0,36,1.875v3.75a1.875,1.875,0,0,0,3.75,0V3.75H51v22.5H39.75V24.375a1.875,1.875,0,0,0-3.75,0v3.75A1.874,1.874,0,0,0,37.875,30h15a1.874,1.874,0,0,0,1.875-1.875V1.875A1.874,1.874,0,0,0,52.875,0Z"
                                  transform="translate(-24.75)"
                                />
                              </g>
                            </svg>
                          ) : (
                            <svg
                              id="Hover_logout"
                              data-name="Hover logout"
                              width="80"
                              height="46"
                              viewBox="0 0 101 46"
                            >
                              <rect
                                id="Rectangle_8564"
                                data-name="Rectangle 8564"
                                width="101"
                                height="46"
                                opacity="0"
                              />
                              <g
                                id="_3994382_access_close_exit_logout_sign_out_icon"
                                data-name="3994382_access_close_exit_logout_sign out_icon"
                                transform="translate(36 8)"
                              >
                                <path
                                  id="Path_17515"
                                  data-name="Path 17515"
                                  d="M6.4,33.375H20.625a1.875,1.875,0,1,0,0-3.75H6.4L8.826,27.2A1.875,1.875,0,1,0,6.174,24.55L.549,30.175a1.874,1.874,0,0,0,0,2.651l5.625,5.625A1.875,1.875,0,1,0,8.826,35.8Z"
                                  transform="translate(0 -16.5)"
                                />
                                <path
                                  id="Path_17516"
                                  data-name="Path 17516"
                                  d="M52.875,0h-15A1.874,1.874,0,0,0,36,1.875v3.75a1.875,1.875,0,0,0,3.75,0V3.75H51v22.5H39.75V24.375a1.875,1.875,0,0,0-3.75,0v3.75A1.874,1.874,0,0,0,37.875,30h15a1.874,1.874,0,0,0,1.875-1.875V1.875A1.874,1.874,0,0,0,52.875,0Z"
                                  transform="translate(-24.75)"
                                />
                              </g>
                            </svg>
                          )}
                        </span>
                        <div className="hideText">
                          <span className="poppinsRegular text-white iconTop">
                            Logout
                          </span>
                        </div>
                      </a>
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* </div> */}
            </div>
            {children.children}
          </div>
          <div
            className="modal fade"
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            // tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered bg-transparent selfModelWidth deleteModalC">
              <div className="modal-content border-0 p-5">
                <div className="modal-body text-start text-center">
                  <h2 className="textGrey">Are you sure you want to logout?</h2>
                  <div className="d-flex justify-content-center mt-3">
                    <button
                      className="btn btnOrganization w-auto px-5 mt-3 me-3 "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      No
                    </button>
                    <button
                      className="btn sign-up-button-color text-white px-5 mt-3"
                      // onClick={() => deleteDepartment()}
                      onClick={() => handleLogOut()}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}
export default AppLayout;
