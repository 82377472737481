import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import setObjective from "../../assets/images/Icons/Pics/Set Objective.png";
import editIcon from "../../assets/images/Icons/SVG/Edit Icon.svg";
import deleteIcon from "../../assets/images/Icons/SVG/Delete Icon.svg";
import departmentIcon from "../../assets/images/Icons/SVG/Icon materialpersonoutline.svg";
import { useFormik } from "formik";
import API from "../../Api/Api";
import RouteName from "../../Routes/Routename";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";

const EditDepartment = (props) => {
   const location = useLocation();
   const queryParams = new URLSearchParams(location.search);
   const editId = queryParams.get("editId");

   // const [departmentList, setDepartmentList] = useState(null);
   // const [departmentData, setDepartmentDataByID] = useState(null);
   const [departmentListLocally, setDepartmentListLocally] = useState(null);
   const [isEditing, setEditing] = useState(false); // New state for edit mode
   const [editingId, setEditingId] = useState(null);

   const navigate = useNavigate();
   const { id } = useParams();

   // console.log("---------------", dept_name, email);
   const validate = (values) => {
      const errors = {};
      const regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
      const spaceReg = /^\S.*\S$/;

      if (!values.dept_name) {
         errors.dept_name = "Please enter department name";
      } else if (!spaceReg.test(values.dept_name)) {
         errors.dept_name = "Cannot start & end with a space";
      } else {
         const selectedDeptName = departmentListLocally?.find(
            (item) => item?.dept_name === values.dept_name && item?.dept_name !== editingId
         );
         if (selectedDeptName) {
            errors.dept_name = `Entered department name already exists`;
         }
      }

      if (!values.email) {
         errors.email = "Please enter email";
      } else if (!regex.test(values.email)) {
         errors.email = "This is not a valid email format";
      }

      if (regex.test(values.email)) {
         const emailParts = values.email.split("@");
         if (emailParts.length > 2 || (emailParts.length === 2 && emailParts[1].includes("@"))) {
            errors.email = "Please enter only one email address";
         }
      }
      return errors;
   };

   const formik = useFormik({
      initialValues: {
         dept_name: "",
         email: "",
      },
      onSubmit: () => {
         // handleSave();
         if (editingId) {
            updateOrganization();
         }
      },
      validate,
   });

   // useEffect(() => {
   //    formik.setValues({
   //       id: editId,
   //       // dept_name: dept_name,
   //       // email: email,
   //    });
   // }, [dept_name, email]);

   const handleEdit = (deptName, email) => {
      setEditingId(id);
      setEditing(true);
      formik.setValues({
         dept_name: deptName,
         email: email,
      });
   };

   useEffect(() => {
      getAllDepartments();
   }, []);

   const getAllDepartments = () => {
      API.PlanRenew.departmentProgressList()
         .then((response) => {
            const departments = response.data.data;
            // setDepartmentListLocally(departments);

            const departmentToEdit = departments.find((dept) => dept._id === id); // compare IDs
            if (departmentToEdit) {
               formik.setValues({
                  dept_name: departmentToEdit.dept_name,
                  email: departmentToEdit.email,
               });
               setEditingId(id); // set the editing ID
               setEditing(true); // enable edit mode
            }
            const updateData = departments.filter((item) => item.is_active);
            console.log(updateData, "updateData");
            setDepartmentListLocally(updateData);
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const updateOrganization = () => {
      // setIsLoading(true);
      // setEditing(true);
      API.PlanRenew.updateDepartmentRenewal({
         data: {
            _id: editingId,
            dept_name: formik.values.dept_name,
            email: formik.values.email,
         },
      })
         .then((response) => {
            if (response.data.statusCode === 200) {
               getAllDepartments();
               formik.resetForm();
               setEditing(false);
               setEditingId(null);
               // setIsLoading(false);
               toast.success("Department updated successfully!");
            }
         })
         .catch((error) => {
            console.error("Error updating department:", error);
            // setIsLoading(false);
         });
   };

   const handleNextButton = () => {
      navigate(`/plan-renewal`, { replace: true });
   };

   const handleCancel = (e) => {
      e.preventDefault();
      formik.resetForm();
      setEditing(false);
      setEditingId(null);
   };
   return (
      <div className="bgColor">
         {/* need tobe changed */}

         <div className="row justify-content-center pt-4">
            <div className="col-12">
               <div className="row">
                  <div className="col-lg-6">
                     <div className="navbar-brand-box d-flex">
                        <span className="logo mt-2">
                           <NavLink to="/dashboard" class="logo logo-light mt-2" style={{ textDecoration: "none" }}>
                              <h2 className="bannerHeading fs-3 mb-0">
                                 EASY<span className="fs-3">OKR</span>
                              </h2>
                           </NavLink>
                        </span>
                     </div>
                  </div>
               </div>
            </div>

            <div className="col-12 p-5">
               <div className="row justify-content-center">
                  <div className="col-11">
                     <div className="emailoOuterContainer p-5 bg-white">
                        <div className="d-flex align-items-center">
                           <NavLink to="/plan-renewal">
                              <div className="backArrow me-2">
                                 <i className="fa fa-solid fa-chevron-left textDarkBlack"></i>
                              </div>
                           </NavLink>
                           <h2 className="textDarkBlack fw-normal h4 mb-0">Edit Department</h2>
                        </div>
                        {/* <p className="textGrey ps-3">Renew your plan to resume your OKR journey</p> */}
                        <div className="row justify-content-center mt-4">
                           <div className="col-xxl-11 col-12">
                              <div className="row">
                                 <div className="col-xl-6 pe-xl-5 pb-5 border-right">
                                    <p className="textBlueLight mb-1">Edit existing departments</p>

                                    <form>
                                       <div className="mb-4">
                                          <div className="form-group">
                                             {/* <label for="exampleFormControlInput1" className="form-label textLightGrey mb-1">
                                                Department Name
                                             </label> */}
                                             <input
                                                type="text"
                                                className="form-control border-0 border-radius-10px"
                                                placeholder="Enter department name"
                                                id="exampleInputfirstname"
                                                name="dept_name"
                                                value={formik.values.dept_name}
                                                onChange={formik.handleChange}
                                             />
                                          </div>
                                          {formik.errors.dept_name && formik.touched.dept_name ? (
                                             <div className="text-danger">{formik.errors.dept_name}</div>
                                          ) : null}
                                       </div>
                                       <div className="mb-4">
                                          <div className="form-group">
                                             {/* <label for="exampleFormControlInput1" className="form-label textLightGrey mb-1">
                                                Email
                                             </label> */}
                                             <input
                                                type="email"
                                                className="form-control border-0 border-radius-10px"
                                                placeholder="Enter department owner's email address"
                                                id="exampleInputfirstname"
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                readOnly={isEditing}
                                             />
                                          </div>
                                          {formik.errors.email && formik.touched.email ? (
                                             <div className="text-danger">{formik.errors.email}</div>
                                          ) : null}
                                       </div>

                                       <div className="d-flex justify-content-between">
                                          <button
                                             type="submit"
                                             className="btn set-objective-button px-5"
                                             onClick={(e) => handleCancel(e)}
                                          >
                                             Cancel
                                          </button>
                                          <button
                                             type="submit"
                                             className="btn sign-up-button-color text-white px-5"
                                             onClick={formik.handleSubmit}
                                          >
                                             Update
                                          </button>
                                       </div>
                                    </form>
                                    {/* <p className="textBlueLight mt-3">
                                       <span className="text-decoration-underline">Note:</span> We suggest you to add not more
                                       than 5 objectives making it achievable.
                                    </p> */}
                                 </div>
                                 <div className="col-xl-6 ps-xl-5">
                                    <p className="textBlueLight">
                                       Here are the list of your existing departments and their departmental owners
                                    </p>
                                    {!departmentListLocally?.length ? (
                                       <div className="row">
                                          <div className="col-12 text-center py-5">
                                             <img src={setObjective} alt="departmemnt name" />
                                          </div>
                                       </div>
                                    ) : (
                                       <>
                                          <div className="d-flex align-items-center justify-content-end mb-2">
                                             <img
                                                src={departmentIcon}
                                                className="me-1"
                                                style={{ width: "15px" }}
                                                alt="department icon"
                                             />{" "}
                                             {departmentListLocally.length}
                                          </div>
                                          <div className="scroll__y ">
                                             {departmentListLocally?.map((item, index) => {
                                                console.log(item, "item");
                                                return (
                                                   <div className="row d-flex align-items-center w-100 mb-2" key={index}>
                                                      <div className="col-11 ">
                                                         <div className="departmentBox p-3">
                                                            <div className="row align-items-center">
                                                               <div className="col-2">
                                                                  <img src={departmentIcon} className="" alt="department icon" />
                                                               </div>
                                                               <div className="col-10">
                                                                  <p className="mb-1" style={{ overflowWrap: "break-word" }}>
                                                                     {item?.dept_name}
                                                                  </p>
                                                                  <p
                                                                     className="textBlueLight1 mb-0"
                                                                     style={{ overflowWrap: "anywhere" }}
                                                                  >
                                                                     {item?.email}
                                                                  </p>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className="col-1">
                                                         <div className="d-flex">
                                                            <img
                                                               width={36}
                                                               className="me-2"
                                                               alt="edit icon"
                                                               src={editIcon}
                                                               onClick={() => handleEdit(item?.dept_name, item?.email)}
                                                            />
                                                            {/* <button
                                                               type="button"
                                                               className="btn p-0"
                                                               data-bs-toggle="modal"
                                                               id="DeleteDepartemnt"
                                                               data-bs-target="#staticBackdrop1"
                                                               onClick={() => handleDelete(item?.dept_name)}
                                                            >
                                                               <img
                                                                  src={deleteIcon}
                                                                  width={36}
                                                                  alt="delete icon"
                                                                 
                                                               />
                                                            </button> */}
                                                         </div>
                                                      </div>
                                                   </div>
                                                );
                                             })}
                                          </div>
                                       </>
                                    )}
                                    <button
                                       className={`btn ${
                                          !departmentListLocally?.length ? "sign-up-button-color-onempty" : "sign-up-button-color"
                                       } text-light w-100 mt-3`}
                                       onClick={() => handleNextButton()}
                                       disabled={!departmentListLocally?.length}
                                    >
                                       Save
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                        {/* delete modal
                         */}
                     </div>
                  </div>
               </div>
            </div>
            {/* need tobe changed  end*/}
         </div>
      </div>
   );
};

export default EditDepartment;
