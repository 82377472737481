import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { NavLink, useNavigate, useParams } from "react-router-dom";
// import departmentImg from "../../assets/images/Icons/SVG/Ellipse 60.png";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";

import API from "../../Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { setObjectiveData } from "../../Redux/Slice";
export default function DepartmentDetails() {
   const [departmentDataa, setdepartmentDataaList] = useState(null);
   const postData = useSelector((state) => state.date.departmentData);
   const { id } = useParams();
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const getSingledepartmentById = () => {
      if (id) {
         API.Auth.GetOrgDepartmentByIdAPI(id)
            .then((response) => {
               setdepartmentDataaList(response.data.data);
            })
            .catch((err) => {
               if (err.response.status === 403) {
                  navigate("/");
               }
               console.log(err);
            });
      }
   };

   useEffect(() => {
      getSingledepartmentById();
   }, [id]);

   const NeededInfo = (objName, KeyResult, description, index) => {
      const newdata = {
         objName: objName,
         KeyResult: KeyResult,
         description: description,
         // percentage: percentage,
         index: index,
      };
      dispatch(setObjectiveData(newdata));
   };
   return (
      <AppLayout>
         <div className="main-content mt-3">
            <div className="page-content mb-5">
               <div className="row welcomeHeading">
                  <div className="col-12 d-sm-flex justify-content-between">
                     <div className="d-flex align-items-center ps-3 mb-3 mb-sm-0">
                        <NavLink className="" to="/department">
                           <div className="backArrow me-2">
                              <i className="fa fa-solid fa-chevron-left textDark"></i>
                           </div>
                        </NavLink>
                        <h3 className="mb-0 fw-bold">{postData?.DeptName}</h3>
                     </div>
                     <div className="d-flex align-items-center me-2">
                        <img
                           src={
                              postData?.DeptImage
                                 ? `${process.env.REACT_APP_BASE_URL}/images/${postData?.DeptImage}`
                                 : departmentImg
                           }
                           className="me-3  Organization-img"
                        />
                        <div>
                           <small className="lightGrey mb-0">{postData?.DeptName} Owner</small>
                           <h5 className="textDarkBlack mb-1 fw-bold">{postData.fullname ? postData.fullname : "-"}</h5>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="row mt-3">
                  <div className="col-xl-12">
                     <h3 class="textDarkBlack mb-1 fw-bold">Organization Objective</h3>
                     <small className="textDarkGrey">
                        Below are the list of your organizational objectives that this department is working towards
                     </small>
                     <div className="accordion accordion-flush mt-2" id="accordionFlushExample1">
                        {departmentDataa &&
                           departmentDataa?.map((item, index) => {
                              return (
                                 <div className="accordion-item mb-3 border-0" key={index}>
                                    <h2 className="accordion-header" id={`flush-heading${index + 1}`}>
                                       <button
                                          className="accordion-button progressCard p-3 collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#flush-collapse${index + 1}`}
                                          aria-expanded="false"
                                          aria-controls={`flush-collapse${index + 1}`}
                                       >
                                          <div className="row w-100 d-flex align-items-center">
                                             <div className="col-lg-6 mb-3">
                                                <h4 className="textBlueLight">#.{item?.objective_name}</h4>
                                                <p className="textDarkBlack mb-0">{item?.description}</p>
                                             </div>
                                             <div className="col-lg-6">
                                                <div className="row align-items-center">
                                                   <div className="col-lg-2 mt-2">
                                                      <h3 className="textBlueLight fw-bold">
                                                         {item?.org_percentage_calculation
                                                            ? Math.round(item?.org_percentage_calculation * 100) / 100
                                                            : 0}
                                                         %
                                                      </h3>
                                                   </div>
                                                   <div className="col-xl-10 col-lg-11">
                                                      <div className="progress">
                                                         <div
                                                            className={`progress-bar`}
                                                            role="progressbar"
                                                            style={{
                                                               width: `${item?.org_percentage_calculation}%`,
                                                            }}
                                                            aria-valuenow={item?.org_percentage_calculation}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                         ></div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </button>
                                    </h2>
                                    <div
                                       id={`flush-collapse${index + 1}`}
                                       className="accordion-collapse collapse"
                                       aria-labelledby={`flush-heading${index + 1}`}
                                       data-bs-parent="#accordionFlushExample1"
                                    >
                                       <div className="accordion-body px-0">
                                          {item?.objectiveList?.length > 0 ? (
                                             <div className="position-relative">
                                                {item?.objectiveList
                                                   .filter((objective) => objective.is_active === true)
                                                   .map((KeyResult, i) => {
                                                      return (
                                                         <NavLink
                                                            className="text-decoration-none"
                                                            to={`/timely-delivery/${KeyResult?._id}`}
                                                            onClick={() =>
                                                               NeededInfo(
                                                                  item?.objective_name,
                                                                  KeyResult?.dept_objective_name,
                                                                  KeyResult?.dept_objective_description,
                                                                  // KeyResult?.percentage_calculation,
                                                                  index
                                                               )
                                                            }
                                                         >
                                                            <div
                                                               key={i}
                                                               className="row w-100 d-flex align-items-center justify-content-center mb-3"
                                                            >
                                                               <div className="col-lg-1 d-flex justify-content-center">
                                                                  <div className="organizationNo">{i + 1}</div>
                                                                  <div className="vLine"></div>
                                                               </div>
                                                               <div className="col-lg-11 p-3 bg-light-blue zIndex">
                                                                  <div className="row align-items-center text-lg-start text-center">
                                                                     <div className="col-lg-10 mb-3">
                                                                        <h4 className="textBlueLight">
                                                                           {KeyResult?.dept_objective_name}
                                                                        </h4>
                                                                        <p className="textDarkBlack mb-0">
                                                                           {KeyResult?.dept_objective_description}
                                                                        </p>
                                                                     </div>
                                                                     <div className="col-lg-2 d-flex justify-content-around align-items-center">
                                                                        <h3 className="textBlueLight fw-bold mb-0">
                                                                           {Math.round(KeyResult?.percentage_calculation * 100) /
                                                                              100}
                                                                           %
                                                                        </h3>
                                                                        <i className="fa fa-solid fa-chevron-right textBlueLight"></i>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </NavLink>
                                                      );
                                                   })}
                                             </div>
                                          ) : (
                                             <div className="position-relative">
                                                <div className="row">
                                                   <div className="col-xl-12">
                                                      <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
                                                         <div className="d-flex align-items-center">No data found</div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          )}
                                       </div>
                                    </div>
                                 </div>
                              );
                           })}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </AppLayout>
   );
}
